import React from 'react';
import { DeviceHealthStatus } from '../../Model/Device';

function DeviceHealth({ health }: { health: DeviceHealthStatus }) {
    switch (health) {
        case DeviceHealthStatus.OFFLINE:
            return (
                <p className="flex gap-2 items-center bg-gray-100 rounded-md py-1 px-2">
                    <span className="bg-red-600 w-3 h-3 rounded-full" />
                    offline
                </p>
            );
        case DeviceHealthStatus.ONLINE:
            return (
                <p className="flex gap-2 items-center bg-gray-100 rounded-md py-1 px-2">
                    <span className="bg-green-600 w-3 h-3 rounded-full" />
                    online
                </p>
            );
        case DeviceHealthStatus.NOT_LOGGED:
            return (
                <p className="flex gap-2 items-center bg-gray-100 rounded-md py-1 px-2">
                    <span className="bg-red-600 w-3 h-3 rounded-full" />
                    not logging
                </p>
            );
        default:
            return null;
    }
}

export default DeviceHealth;
