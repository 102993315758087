import React from 'react';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import styles from './popup.module.css';
import { PopupPosition } from './PopupPosition';
import calculatePosition from './calculatePosition';
import useScrollListener from '../../utils/useScrollListner';

interface Props extends React.HTMLProps<HTMLDialogElement> {
    anchorRef: React.RefObject<HTMLElement>; // The reference to the element that the menu should be anchored to
    position: PopupPosition; // The position of the menu relative to the anchor element
    spacing?: number; // The spacing between the menu and the anchor element (default is 0)
    children: React.ReactNode; // The content of the menu
    onClose: () => void; // Function to be called when the popup is closed
}

const Popup = React.forwardRef(
    (props: Props, ref: React.LegacyRef<HTMLDialogElement>) => {
        const {
            anchorRef,
            position,
            spacing,
            children,
            onClose,
            className,
            ...elementProps
        } = props;

        const popupRef = React.useRef<HTMLDialogElement>(
            ref as unknown as HTMLDialogElement,
        );

        const elIsScrolled = useScrollListener(anchorRef);

        React.useEffect(() => {
            const handleClickOutside = (event: MouseEvent) => {
                if (
                    popupRef.current &&
                    !popupRef.current.contains(event.target as Node)
                ) {
                    onClose();
                }
            };
            const handleEscapeKey = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    onClose();
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleEscapeKey);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
                document.removeEventListener('keydown', handleEscapeKey);
            };
        }, [onClose]);

        React.useEffect(() => {
            if (elIsScrolled) {
                onClose();
            }
        }, [elIsScrolled, onClose]);

        React.useEffect(() => {
            const popupRect = popupRef.current?.getBoundingClientRect();
            const anchorRect = anchorRef.current?.getBoundingClientRect();
            if (popupRect && anchorRect) {
                const { width: popupWidth, height: popupHeight } = popupRect;
                const {
                    innerWidth: viewportWidth,
                    innerHeight: viewportHeight,
                } = window;
                const style = calculatePosition({
                    popup: { width: popupWidth, height: popupHeight },
                    anchor: anchorRect,
                    windowEl: { width: viewportWidth, height: viewportHeight },
                    position,
                });
                popupRef.current.style.top = `${style.top}px`;
                popupRef.current.style.left = `${style.left}px`;
                popupRef.current.style.visibility = 'visible';
            }
        }, [popupRef, anchorRef, position, spacing]);

        return createPortal(
            <dialog
                className={clsx(styles.popup, className)}
                open
                ref={popupRef}
                {...elementProps}
            >
                {children}
            </dialog>,
            document.body,
        );
    },
);

export default Popup;
