import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Activity } from '../../Components/Icon';
import Select from '../../Components/Select';
import { AVAILABLETIMEFRAMES, DataFilter } from '../../Model/Analytics';
import { AnalyticsService } from '../../api';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend,
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};

interface Props {
    filters: DataFilter;
}
type BARDATA = { completed: number[]; incomplete: number[] };
function DateChart({ filters }: Props) {
    const [selected, setSelected] = React.useState<
        AVAILABLETIMEFRAMES | 'RELOADING'
    >('HOURS');
    const [labels, setLabels] = React.useState<string[]>([]);
    const [data, setData] = React.useState<BARDATA>({
        completed: [],
        incomplete: [],
    });

    const getData = () => {
        AnalyticsService.GetAnalyticsByTimeFrame({
            ...filters,
            timeframe: selected as AVAILABLETIMEFRAMES,
        }).then((response) => {
            const tempData: BARDATA = {
                completed: [],
                incomplete: [],
            };
            const tempLabels: string[] = [];
            response.forEach((el) => {
                tempData.completed.push(el.success);
                tempLabels.push(el.label);
                tempData.incomplete.push(el.total - el.success);
            });

            setLabels(tempLabels);
            setData(tempData);
        });
    };

    React.useEffect(() => {
        setSelected('RELOADING');
        setTimeout(() => setSelected('HOURS'), 1000);
    }, [filters]);

    React.useEffect(() => {
        if (selected !== 'RELOADING') getData();
    }, [selected]);

    const checkIsDisabled = (el: string) => {
        const from = new Date(filters.from);
        const to = filters.to ? new Date(filters.to) : new Date();
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const millisecondsPerWeek = 7 * millisecondsPerDay;
        const millisecondsPerMonth = 30 * millisecondsPerDay;
        const range = to.getTime() - from.getTime();
        if (el === 'DAYS' && range > millisecondsPerMonth) return true;
        if (el === 'MONTHS' && range < millisecondsPerMonth) return true;
        if (el === 'WEEK' && range < millisecondsPerWeek) return true;
        return false;
    };
    return (
        <div className="bg-white/50 rounded-lg border border-gray-200 p-5 w-full mt-6">
            <div className="flex justify-between">
                <div className="flex gap-2 items-center">
                    <Activity className="w-5 h-5" />
                    <p className="text-lg text-primary font-medium">
                        INTERACTIONS
                    </p>
                </div>
                <div>
                    <Select
                        value={selected ?? ''}
                        onChange={(e) =>
                            setSelected(e.target.value as AVAILABLETIMEFRAMES)
                        }
                    >
                        {['HOURS', 'DAYS', 'MONTHS', 'WEEK'].map(
                            (el, index) => (
                                <option
                                    key={`${index.toString()}_selection`}
                                    disabled={checkIsDisabled(el)}
                                >
                                    {el}
                                </option>
                            ),
                        )}
                    </Select>
                </div>
            </div>
            <hr className="py-1" />
            <Bar
                options={options}
                data={{
                    labels,
                    datasets: [
                        {
                            label: 'completed',
                            data: data.completed,
                            maxBarThickness: 80,
                            backgroundColor: 'rgba(75, 192, 192, 0.5)',
                        },
                        {
                            label: 'Incomplete',
                            data: data.incomplete,
                            maxBarThickness: 80,
                            backgroundColor: 'rgba(255, 206, 86, 0.5)',
                        },
                    ],
                }}
            />
        </div>
    );
}

export default DateChart;
