import React from 'react';
import { ReactComponent as AddPageIcon } from './BuilderToolIcons/file-plus.svg';
import { ReactComponent as TextIcon } from './BuilderToolIcons/type.svg';
import { ReactComponent as ImageIcon } from './BuilderToolIcons/image.svg';
import { ReactComponent as ClockIcon } from './BuilderToolIcons/clock.svg';
import { ReactComponent as GameIcon } from './BuilderToolIcons/gamepad-light.svg';
import { ReactComponent as RadioIcon } from './BuilderToolIcons/circle.svg';
import { ReactComponent as CheckBoxIcon } from './BuilderToolIcons/check-square.svg';
import { ReactComponent as ButtonIcon } from './BuilderToolIcons/bullseye-pointer-light.svg';
import { ReactComponent as InputIcon } from './BuilderToolIcons/input-pipe-light.svg';
import { ReactComponent as TextAreaIcon } from './BuilderToolIcons/pen-field-light.svg';
import { ReactComponent as KeyboardIcon } from './BuilderToolIcons/keyboard-light.svg';
import { ReactComponent as VideoIcon } from './BuilderToolIcons/video.svg';
import { ReactComponent as UploadIcon } from './BuilderToolIcons/upload.svg';
import { ReactComponent as FontIcon } from './BuilderToolIcons/font-case-light.svg';
import { ReactComponent as QrIcon } from './BuilderToolIcons/qrcode-light.svg';
import { ReactComponent as CSSIcon } from './BuilderToolIcons/css3-alt.svg';
import { BuilderContext } from './state/reducer';
import { BuilderActionType } from './state/actions';
import Tooltip from '../../Components/Tooltip';
import { VendexElementType } from '../../Model/VendexElement';
import View from '../../Model/View';
import IconButton from '../../Components/IconButton';

function Toolbar() {
    const { dispatch } = React.useContext(BuilderContext);
    const handleAddElement = (type: VendexElementType) => {
        dispatch({
            type: BuilderActionType.ADD_ELEMENT,
            payload: type,
        });
    };

    const handleMediaLibrary = () => {
        dispatch({
            type: BuilderActionType.SET_MEDIA_LIBRARY,
            payload: { open: 'UPLOAD' },
        });
    };

    return (
        <div
            onClick={(e) => e.stopPropagation()}
            role="none"
            className="shadow-lg w-12 h-screen z-20 left-0 top-0 fixed pt-6 pb-4 flex flex-col gap-4 items-center bg-gray-100 align-top overflow-y-auto"
        >
            <Tooltip text="Upload Media">
                <IconButton onClick={handleMediaLibrary}>
                    <UploadIcon className="bg-gray-100" />
                </IconButton>
            </Tooltip>
            <Tooltip text="Edit Css">
                <IconButton
                    onClick={() => {
                        dispatch({
                            type: BuilderActionType.SET_VIEW,
                            payload: View.CSS,
                        });
                    }}
                >
                    <CSSIcon style={{ color: 'red', fill: 'red' }} />
                </IconButton>
            </Tooltip>
            <Tooltip text="Font Settings">
                <IconButton
                    onClick={() =>
                        dispatch({ type: BuilderActionType.TOGGLE_FONT })
                    }
                >
                    <FontIcon />
                </IconButton>
            </Tooltip>
            <Tooltip text="Add Page">
                <IconButton
                    onClick={() =>
                        dispatch({ type: BuilderActionType.TOOGLE_ADD_PAGE })
                    }
                    btnSize="large"
                >
                    <AddPageIcon className="text-blue-600" />
                </IconButton>
            </Tooltip>
            <hr />
            <Tooltip text="Add Text">
                <IconButton
                    onClick={() => handleAddElement(VendexElementType.TEXT)}
                >
                    <TextIcon />
                </IconButton>
            </Tooltip>
            <Tooltip text="Add Image">
                <IconButton
                    onClick={() => handleAddElement(VendexElementType.IMAGE)}
                >
                    <ImageIcon />
                </IconButton>
            </Tooltip>
            <Tooltip text="Add Timer">
                <IconButton
                    onClick={() => handleAddElement(VendexElementType.TIMER)}
                >
                    <ClockIcon />
                </IconButton>
            </Tooltip>
            <Tooltip text="Add Button">
                <IconButton
                    onClick={() => handleAddElement(VendexElementType.BUTTON)}
                >
                    <ButtonIcon />
                </IconButton>
            </Tooltip>
            <Tooltip text="Add Input">
                <IconButton
                    onClick={() => handleAddElement(VendexElementType.INPUT)}
                >
                    <InputIcon />
                </IconButton>
            </Tooltip>
            <Tooltip text="Add Multiline Input">
                <IconButton
                    onClick={() =>
                        handleAddElement(VendexElementType.MULTILINE)
                    }
                >
                    <TextAreaIcon />
                </IconButton>
            </Tooltip>
            <Tooltip text="Add Keyboard">
                <IconButton
                    onClick={() => handleAddElement(VendexElementType.KEYBOARD)}
                >
                    <KeyboardIcon />
                </IconButton>
            </Tooltip>
            <Tooltip text="Add Video">
                <IconButton
                    onClick={() => handleAddElement(VendexElementType.VIDEO)}
                >
                    <VideoIcon />
                </IconButton>
            </Tooltip>
            <Tooltip text="Add Checkbox">
                <IconButton
                    onClick={() =>
                        handleAddElement(VendexElementType.CHECK_BOX)
                    }
                >
                    <CheckBoxIcon />
                </IconButton>
            </Tooltip>
            <Tooltip text="Add Radio">
                <IconButton
                    onClick={() => handleAddElement(VendexElementType.RADIO)}
                >
                    <RadioIcon />
                </IconButton>
            </Tooltip>
            <Tooltip text="Add Game">
                <IconButton
                    onClick={() =>
                        handleAddElement(VendexElementType.POP_IT_GAME)
                    }
                >
                    <GameIcon />
                </IconButton>
            </Tooltip>
            <Tooltip text="Add Qr Code">
                <IconButton
                    onClick={() => handleAddElement(VendexElementType.QRCODE)}
                >
                    <QrIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
}
export default Toolbar;
