import React from 'react';
import { DeviceService } from '../../api';
import Device from '../../Model/Device';
import Modal, { ModalActions } from '../../Components/Modal';
import { CheckSquare, Square } from '../../Components/Icon';
import DeviceFeatures from '../Devices/DeviceFeatures';
import Button from '../../Components/Button';

interface Props {
    experienceId: string;
    onClose: () => void;
}

function AssignDevice({ experienceId, onClose }: Props) {
    const [devices, setDevices] = React.useState<Device[]>([]);

    const getData = () => {
        DeviceService.GetDevices().then((response) => setDevices(response));
    };

    React.useEffect(() => {
        getData();
    }, []);

    const handleSelection = (device: Device) => {
        DeviceService.UpdateDevice({
            ...device,
            experienceId:
                device.experienceId === experienceId ? undefined : experienceId,
        }).then(() => {
            getData();
        });
    };

    return (
        <Modal
            open
            close={onClose}
            className="min-h-0"
            style={{ maxHeight: '60vh' }}
        >
            <h4 className="text-xl font-semibold">EXPERIENCE DEVICES</h4>
            <p className="mt-4">
                Select devices you would like to assign the experience to
            </p>
            <div className="flex flex-col gap-3 mt-4 mb-16">
                {devices.map((device) => (
                    <div
                        key={device.id}
                        className="flex gap-2 bg-gray-50 shadow-md rouned-md p-4 items-start hover:bg-gray-100 relative"
                        role="button"
                        onClick={() => handleSelection(device)}
                        onKeyDown={(e) =>
                            e.key === 'Enter' && handleSelection(device)
                        }
                        tabIndex={0}
                    >
                        {device.experienceId === experienceId ? (
                            <CheckSquare className="text-green-600 w-6 h-6" />
                        ) : (
                            <Square className="w-6 h-6" />
                        )}
                        <div className="absolute top-2 right-2">
                            <p className="text-blue-600 text-xs">assigned</p>
                        </div>
                        <div>
                            <p className="text-xs font-bold mt-2">
                                SERIAL NUMBER
                            </p>
                            <h1 className="font-thin">{device.serialNumber}</h1>
                            <p className="text-xs font-bold mt-2">NICKNAME</p>
                            <p className="font-thin">
                                {device.nickName ?? 'UNTITLED'}
                            </p>
                            <DeviceFeatures device={device} />
                        </div>
                    </div>
                ))}
            </div>
            <ModalActions>
                <Button className="bg-primary text-white" onClick={onClose}>
                    Close
                </Button>
            </ModalActions>
        </Modal>
    );
}

export default AssignDevice;
