import Device, { DeviceDetailResp } from '../Model/Device';
import DeviceTimeline from '../Model/DeviceTimeline';
import Inventory, { InventoryReq } from '../Model/Inventory';
import instance from './instance';

function GetDevices(): Promise<Device[]> {
    return instance.get(`/devices`).then((response) => {
        return response.data;
    });
}

function UpdateDevice(device: Device): Promise<boolean> {
    return instance.put(`/device`, device).then((res) => {
        return res.data;
    });
}

function DeleteDevice(id: string): Promise<boolean> {
    return instance.delete(`/device/${id}`).then((response) => {
        return response.data;
    });
}

function GetDeviceById(id: string): Promise<DeviceDetailResp> {
    return instance.get(`/device/${id}`).then((response) => {
        return response.data;
    });
}

function GetDeviceTimeline(id: string): Promise<DeviceTimeline[]> {
    return instance.get(`/device/${id}/timeline`).then((response) => {
        return response.data;
    });
}

function LeaseDevice(leaseTerm: DeviceTimeline) {
    return instance.post(`/device/timeline`, leaseTerm).then((response) => {
        return response.data;
    });
}

function DeleteLease(id: string): Promise<boolean> {
    return instance.delete(`/device/timeline/${id}`).then((response) => {
        return response.data;
    });
}

function UpdateLease(leaseTerm: DeviceTimeline): Promise<boolean> {
    return instance.put(`/device/timeline`, leaseTerm).then((response) => {
        return response.data;
    });
}

function GetInventory(serialNumber: string): Promise<Inventory> {
    return instance
        .get(`/device/${serialNumber}/inventories`)
        .then((response) => {
            const data = JSON.parse(response.data);
            return data;
        });
}

function UpdateInventory(req: InventoryReq[]): Promise<boolean> {
    return instance.put(`/inventory`, req).then((response) => {
        return response.data;
    });
}

function RestockInventory(serialNumber: string): Promise<boolean> {
    return instance.get(`/device/${serialNumber}/restock`).then((response) => {
        return response.data;
    });
}

function GenerateLicense(serialNumber: string): Promise<string> {
    return instance.get(`/license/${serialNumber}`).then((response) => {
        return response.data;
    });
}

function GenerateAdminQr(serialNumber: string): Promise<string> {
    return instance.get(`/qr/${serialNumber}/admin`).then((response) => {
        return response.data;
    });
}
function UpdatesNotes(id: string, notes: string): Promise<boolean> {
    return instance
        .put(`/device/notes`, {
            id,
            notes,
        })
        .then((response) => {
            return response.data;
        });
}

export {
    GetDevices,
    DeleteDevice,
    UpdateDevice,
    GetDeviceById,
    GetDeviceTimeline,
    LeaseDevice,
    DeleteLease,
    UpdateLease,
    GetInventory,
    UpdateInventory,
    RestockInventory,
    GenerateLicense,
    GenerateAdminQr,
    UpdatesNotes,
};
