// eslint-disable-next-line import/no-extraneous-dependencies
import { clsx } from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import useRoute from '../../../LayoutProvider/RouteProvider/useRoute';
import NavRoute from '../../../LayoutProvider/NavRoute';

function NavItems() {
    const { navItems, selected } = useRoute();

    const isActive = (item: NavRoute): boolean => {
        if (item.path === '/' && selected?.path === '/') return true;
        if (
            item.path !== '/' &&
            selected &&
            (selected.path === item.path || selected.path.includes(item.path))
        ) {
            return true;
        }
        return false;
    };
    return (
        <div className="flex flex-col gap-2">
            {navItems.map((el) => (
                <React.Fragment key={el.path}>
                    {el.helperText && (
                        <p className="text-xs text-gray-600 pt-4">
                            {el.helperText}
                        </p>
                    )}
                    <Link to={el.path}>
                        <div
                            className={clsx(
                                'flex gap-2 items-center hover:bg-gray-100 p-2 rounded text-primary hover:text-primary',
                                isActive(el) &&
                                    ' hover:bg-primary hover:text-white bg-primary-light text-white',
                            )}
                        >
                            {el.Icon && <el.Icon />}
                            <p className="text-md">{el.label}</p>
                        </div>
                    </Link>
                </React.Fragment>
            ))}
        </div>
    );
}

export default NavItems;
