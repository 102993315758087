const getImageFromFile = async (file: File): Promise<string> => {
    let reader: any;
    return new Promise<string>((resolve, reject) => {
        reader = new FileReader();
        reader.onload = () => {
            const src = reader.result as string;
            resolve(src);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    }).finally(() => {
        reader.abort();
    });
};

export default getImageFromFile;
