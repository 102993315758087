import { useEffect, useState } from 'react';

function useScrollListener(ref: React.RefObject<HTMLElement>) {
    const [pos, setPos] = useState<{ top: number; left: number }>();

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const handleScroll = () => {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                if (rect.top !== pos?.top || rect.left !== pos?.left) {
                    setPos({ top: rect.top, left: rect.left });
                }
            }
        };
        if (ref.current) {
            const parentElementsWithOverflow: HTMLElement[] = [];
            let currentElement = ref.current.parentNode as HTMLElement;
            while (
                currentElement != null &&
                (currentElement as any) !== document
            ) {
                if (currentElement.scrollHeight > currentElement.clientHeight) {
                    parentElementsWithOverflow.push(currentElement);
                    currentElement.addEventListener('scroll', handleScroll);
                }
                currentElement = currentElement.parentNode as HTMLElement;
            }
            return () => {
                parentElementsWithOverflow.forEach((element) => {
                    element.removeEventListener('scroll', handleScroll);
                });
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    return pos;
}

export default useScrollListener;
