import getLabelValue from '../Containers/Dashboard/labelMap';
import {
    ProductAnalytics,
    UserInteractionByTimeFilter,
    SessionAnalytics,
    UserInteractionAnalytics,
} from '../Model/Analytics';
import ExperienceSession from '../Model/ExperienceSession';
import Paginate from '../Model/Paginate';
import downloadFile from '../utils/dowloadFile';
import instance from './instance';

function GetProductAnalytics(filters: {
    [key: string]: string | undefined;
}): Promise<ProductAnalytics[]> {
    return instance
        .post(`/logs/product/analytics`, {
            ...filters,
            from: filters.from
                ? new Date(filters.from).toISOString()
                : undefined,
            to: filters.to ? new Date(filters.to).toISOString() : undefined,
        })
        .then((response) => {
            return response.data;
        });
}

function GetSessionAnalytics(filters: {
    [key: string]: string | undefined;
}): Promise<SessionAnalytics> {
    return instance
        .post(`/logs/session/analytics`, {
            ...filters,
            from: filters.from
                ? new Date(filters.from).toISOString()
                : undefined,
            to: filters.to ? new Date(filters.to).toISOString() : undefined,
        })
        .then((response) => {
            return response.data[0];
        });
}

function GetAverageSession(experienceId: string): Promise<string> {
    return instance
        .get(`/logs/experience/${experienceId}/duration`)
        .then((response) => {
            return response.data;
        });
}

function GetAnalyticsByTimeFrame(
    filters: UserInteractionByTimeFilter,
): Promise<UserInteractionAnalytics[]> {
    return instance
        .post(`/logs/userInteraction/analytics`, {
            ...filters,
            from: filters.from
                ? new Date(filters.from).toISOString()
                : undefined,
            to: filters.to ? new Date(filters.to).toISOString() : undefined,
        })
        .then((response) => {
            const temp: UserInteractionAnalytics[] = [];
            response.data.forEach((el: UserInteractionAnalytics) => {
                temp.push({
                    ...el,
                    label: getLabelValue(filters.timeframe, el.label),
                });
            });
            return temp;
        });
}

function GetExperienceData(
    experienceId: string,
    page: number,
    pageSize: number,
): Promise<Paginate<ExperienceSession>> {
    return instance
        .post(`/logs/experience/data`, {
            experienceId,
            page,
            pageSize,
        })
        .then((response) => {
            return response.data;
        });
}

function DownloadExperienceData(experienceId: string): Promise<boolean> {
    return instance
        .post(
            `/logs/experience/csv`,
            {
                experienceId,
            },
            {
                responseType: 'blob',
            },
        )
        .then((response) => {
            return downloadFile(
                response.data,
                `experience_${new Date().toLocaleString()}.csv`,
            );
        });
}

export {
    GetProductAnalytics,
    GetSessionAnalytics,
    GetAnalyticsByTimeFrame,
    GetExperienceData,
    DownloadExperienceData,
    GetAverageSession,
};
