import React from 'react';
import { ProductReq } from '../../Model/Product';
import Modal from '../../Components/Modal';
import Button from '../../Components/Button';
import Input from '../../Components/Input';
import UploadButton from '../../Components/UploadButton';
import getImageFromFile from '../../utils/getImageFromFile';
import { isValidPriceAmount } from '../../utils/validations';
import TextArea from '../../Components/TextArea';
import { CreateProduct, UpdateProduct } from '../../api/productService';
import CompanySelector from '../../Components/CompanySelector';
import Thumbnail from '../../Components/Thumbnail';

interface Props {
    product?: ProductReq;
    onClose: () => void;
    refreshData: () => void;
}

const initProduct: ProductReq = {
    companyId: '',
    price: 0.0,
    name: '',
    minimumInventoryTrigger: 4,
    maxAllowed: 10
};

function ProductModal({ product = initProduct, onClose, refreshData }: Props) {
    const [data, setData] = React.useState(product);
    const [showError, setShowError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const isValid = () => {
        return (
            data.name.length > 0 &&
            data.companyId.length > 0 &&
            isValidPriceAmount(data.price) &&
            data.maxAllowed >= 1 &&
            data.minimumInventoryTrigger >= 1
        );
    };

    const handleSubmit = () => {
        if (isValid()) {
            setLoading(true);
            setShowError(false);
            const req = data.hasOwnProperty('id')
                ? UpdateProduct(data)
                : CreateProduct(data);
            req.then(() => {
                refreshData();
                onClose();
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setShowError(true);
        }
    };

    const handleChange = (name: string, value: string | number) => {
        setData({ ...data, [name]: value });
    };

    const handleUpload = async (files: File[]) => {
        const imageSource = await getImageFromFile(files[0]);
        setData({ ...data, thumbnail: imageSource, file: files[0] });
    };

    return (
        <Modal open close={onClose} className="max-w-xl">
            <div className="flex gap-4 items-center  mb-2">
                <Thumbnail name={data.name} url={data.thumbnail} />
                <p className="text-xl font-semibold primary">
                    {data.hasOwnProperty('id')
                        ? 'EDIT PRODUCT'
                        : 'CREATE PRODUCT'}
                </p>
            </div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit();
                }}
            >
                <CompanySelector
                    value={data.companyId}
                    onChange={(value) => handleChange('companyId', value)}
                    allowUpdate={!data.hasOwnProperty('id')}
                />
                <div className="grid lg:grid-cols-2 gap-4">
                    <Input
                        label="Name"
                        onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                        }
                        value={data.name}
                        name="name"
                        error={showError && data.name.length === 0}
                        required
                    />
                    <Input
                        label="Price"
                        onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                        }
                        value={data.price}
                        name="price"
                        error={showError && !isValidPriceAmount(data.price)}
                        required
                    />
                </div>
                <div className="grid lg:grid-cols-2 gap-4">
                    <Input
                        label="Minimum Inventory trigger"
                        onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                        }
                        value={data.minimumInventoryTrigger}
                        name="minimumInventoryTrigger"
                        error={showError && data.minimumInventoryTrigger < 1}
                        helperText="Product inventory count that triggers lower inventory notification"
                        required
                    />
                    <Input
                        label="Maximum on a channel"
                        onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                        }
                        value={data.maxAllowed}
                        name="maxAllowed"
                        error={showError && data.maxAllowed < 1}
                        helperText="Maximum number of products that can fit in the channel"
                        required
                    />
                </div>
                <TextArea
                    label="description"
                    onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                    }
                    value={data.description}
                    name="description"
                />
                <UploadButton
                    className="w-full bg-secondary text-white mb-2"
                    onSelect={handleUpload}
                >
                    {data.thumbnail && data.thumbnail.length > 0
                        ? 'CHANGE THUMBNAIL'
                        : 'UPLOAD THUMBNAIL'}
                </UploadButton>
                <div className="flex gap-2 justify-end mt-2">
                    <Button
                        className="bg-primary-light text-white"
                        type="submit"
                        loading={loading}
                    >
                        Submit
                    </Button>
                </div>
            </form>
        </Modal>
    );
}

export default ProductModal;
