import React from 'react';
import useDebounce from '../../utils/useDebounce';

interface Props {
    value?: string;
    onChange: (value: string) => void;
}
function NotesEditor({ value = '', onChange }: Props) {
    const [note, setNote] = React.useState(value);
    const [init, setInit] = React.useState(false);
    const debounced = useDebounce(note, 2000);

    React.useEffect(() => {
        if (!init) {
            setInit(true);
        } else {
            onChange(debounced);
        }
    }, [debounced]);

    return (
        <div
            className="rounded-md flex flex-col hover:cursor-pointer flex-grow"
            style={{ minWidth: '320px', minHeight: '240px' }}
        >
            <div className="flex gap-2 px-4 py-3 rounded-t-md items-center bg-white/50 border border-gray-200 justify-between">
                <h4 className="text-lg">Notes</h4>
            </div>
            <textarea
                className="border flex-grow p-2"
                value={note ?? ''}
                onChange={(e) => setNote(e.target.value)}
                placeholder="add notes here ..."
            />
        </div>
    );
}

export default NotesEditor;
