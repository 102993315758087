import React from 'react';
import Modal from '../../Components/Modal';
import CompanySelector from '../../Components/CompanySelector';
import { DeviceService } from '../../api';
import Device from '../../Model/Device';
import Button from '../../Components/Button';

interface Props {
    onClose: () => void;
    device: Device;
    refreshData: () => void;
}
function PurchaseDialog({ onClose, device, refreshData }: Props) {
    const [companyId, setCompanyId] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const handleSubmit = () => {
        setLoading(true);
        DeviceService.UpdateDevice({
            ...device,
            companyId,
            isPurchased: true,
        })
            .then(() => {
                refreshData();
                onClose();
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <Modal open close={onClose}>
            <p className="text-xl font-semibold primary">
                MARK DEVICE AS PURCHASED
            </p>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit();
                }}
            >
                <CompanySelector
                    value={companyId}
                    onChange={(val) => setCompanyId(val)}
                />
                <div className="flex gap-2 justify-end mt-2">
                    <Button
                        className="bg-primary-light text-white"
                        type="submit"
                        disabled={companyId.length === 0}
                        loading={loading}
                    >
                        Submit
                    </Button>
                </div>
            </form>
        </Modal>
    );
}

export default PurchaseDialog;
