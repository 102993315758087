import instance from './instance';
import Experience, { ExpReq } from '../Model/Experience';
import { Media } from '../Model/Media';
import VendExp from '../Model/VendExp';
import Product from '../Model/Product';
import Device from '../Model/Device';
import downloadFile from '../utils/dowloadFile';

function GetExperience(id: string): Promise<Experience> {
    return instance.get(`/experience/${id}`).then((response) => {
        return response.data;
    });
}

function CreateExperience(req: ExpReq): Promise<boolean> {
    return instance.post(`/experience`, req).then((res) => {
        return res.data;
    });
}

function UpdateExperience(req: ExpReq): Promise<boolean> {
    return instance.put(`/experience`, req).then((res) => {
        return res.data;
    });
}

function DeleteExperience(id: string): Promise<boolean> {
    return instance.delete(`/experience/${id}`).then((response) => {
        return response.data;
    });
}

function GetExperiences(): Promise<Experience[]> {
    return instance.get(`/experiences`).then((response) => {
        return response.data;
    });
}

function GetVendExpAssetsById(experienceId: string): Promise<Media[]> {
    return instance
        .get(`/experience/${experienceId}/assets-meta-data`)
        .then((response) => {
            return response.data;
        });
}

function GetVendExpById(experienceId: string): Promise<VendExp> {
    return instance.get(`/experience/${experienceId}/json`).then((response) => {
        const resp = JSON.parse(response.data);
        return resp;
    });
}
function UpdateVendExp(vendExp: VendExp): Promise<boolean> {
    return instance
        .put(`/experience/json`, {
            jsonReq: JSON.stringify(vendExp),
        })
        .then((response) => {
            return response.data;
        });
}

function UploadVendExpAssets(
    experienceId: string,
    files: File[],
): Promise<boolean> {
    const formData = new FormData();
    formData.append('id', experienceId);
    files.forEach((element) => {
        formData.append('files', element);
    });
    return instance
        .post(`/experience/upload-assets`, formData)
        .then((response) => {
            return response.data;
        });
}

function PropagateExperienceChanges(experienceId: string): Promise<boolean> {
    return instance.patch(`/experience/${experienceId}`).then((response) => {
        return response.data;
    });
}

function GetExperienceProducts(experienceId: string): Promise<Product[]> {
    return instance
        .get(`/experience/${experienceId}/products`)
        .then((response) => {
            return response.data;
        });
}

function GetExperienceDevices(experienceId: string): Promise<Device[]> {
    return instance
        .get(`/experience/${experienceId}/devices`)
        .then((response) => {
            return response.data;
        });
}

function DownloadExperienceForDevice(id: string): Promise<boolean> {
    return instance
        .get(`/experience/${id}/download`, {
            responseType: 'blob',
        })
        .then((response) => {
            return downloadFile(response.data, `${id}.zip`);
        });
}

function UpdatesNotes(id: string, notes: string): Promise<boolean> {
    return instance
        .put(`/experience/notes`, {
            id,
            notes,
        })
        .then((response) => {
            return response.data;
        });
}

function UpdateVendExpBuilder(id: string, version: string): Promise<boolean> {
    return instance
        .put(`/experience/builder`, {
            id,
            builderVersion: version,
        })
        .then((response) => {
            return response.data;
        });
}

export {
    GetExperience,
    CreateExperience,
    UpdateExperience,
    DeleteExperience,
    GetExperiences,
    GetVendExpById,
    GetVendExpAssetsById,
    UpdateVendExp,
    UploadVendExpAssets,
    PropagateExperienceChanges,
    GetExperienceProducts,
    GetExperienceDevices,
    DownloadExperienceForDevice,
    UpdatesNotes,
    UpdateVendExpBuilder,
};
