import React from 'react';
import Experience from '../../Model/Experience';
import { Thermometer, Wifi } from '../../Components/Icon';

function ExperienceFeatures({ exp }: { exp: Experience }) {
    return (
        <div className="flex gap-2 mt-2">
            {exp.requiresInternet && (
                <div className="flex gap-2">
                    <Wifi className="text-blue-300 w-4 h-4" />
                    <p className="text-xs">Requires Internet</p>
                </div>
            )}
            {exp.requiresRefrigeration && (
                <div className="flex gap-2">
                    <Thermometer className="text-blue-500 w-4 h-4" />
                    <p className="text-xs">Requires Refridgeration</p>
                </div>
            )}
        </div>
    );
}

export default ExperienceFeatures;
