/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import prettier from 'prettier/standalone';
import cssParser from 'prettier/parser-postcss';

export function formatCSS(css: string) {
    return prettier.format(css, {
        parser: 'css',
        plugins: [cssParser],
    });
}

export const checkParitySuccess = (value: string): boolean => {
    return value.includes('_start_') && value.includes('_end_');
};

export const removeParity = (value: string): string => {
    return value.substring(
        value.indexOf('_start_') + 7,
        value.indexOf('_end_'),
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getListFromEnum = (
    enumvalues: string | any[],
): { label: string; value: string | number }[] => {
    const resp: { label: string; value: string | number }[] = [];
    const halfLength = Math.ceil(enumvalues.length / 2);
    const labels = enumvalues.slice(0, halfLength) as string[];
    const values = enumvalues.slice(halfLength, enumvalues.length);
    labels.forEach((el, index) => {
        resp.push({
            label: el,
            value: values[index],
        });
    });
    return resp;
};

export const getStyleProperties = (
    selector: string,
    properties: string[],
): { [key: string]: string } => {
    const obj: { [key: string]: string } = {};
    const styleElement = document.querySelector(
        `style[data-id="vendex-styles"]`,
    );
    if (styleElement) {
        const styleSheet = (styleElement as HTMLStyleElement)
            .sheet as CSSStyleSheet;
        const rules = styleSheet.cssRules || styleSheet.rules;

        // Find the rule for the given selector
        let rule: CSSStyleRule | null = null;
        for (let i = 0; i < rules.length; i += 1) {
            const cssRule = rules[i] as CSSStyleRule;
            if (cssRule.selectorText === selector) {
                rule = cssRule;
                break;
            }
        }
        if (rule) {
            // Get the values for the requested properties
            for (const property of properties) {
                const value = rule.style.getPropertyValue(property) ?? '';
                obj[property] = value;
            }
        }
    }
    return obj;
};

export const setStyleProperties = (
    selector: string,
    properties: { [key: string]: string },
): string => {
    const styleElement = document.querySelector(
        `style[data-id="vendex-styles"]`,
    );
    let cssString = '';

    if (styleElement) {
        const styleSheet = (styleElement as HTMLStyleElement)
            .sheet as CSSStyleSheet;
        // Find the rule for the given selector, or create a new rule if it doesn't exist
        let rule: CSSStyleRule | null = null;

        for (const cssRule of styleSheet.cssRules) {
            if (
                cssRule instanceof CSSStyleRule &&
                cssRule.selectorText === selector
            ) {
                rule = cssRule;
                break;
            }
        }
        if (!rule) {
            styleSheet.insertRule(`${selector} {}`, styleSheet.cssRules.length);
            rule = styleSheet.cssRules[
                styleSheet.cssRules.length - 1
            ] as CSSStyleRule;
        }
        // Update or add the properties to the rule
        for (const property in properties) {
            rule.style.setProperty(property, properties[property]);
        }
        for (const cssRule of styleSheet.cssRules) {
            cssString += cssRule.cssText;
        }
    }
    return cssString;
};

export const deleteStyleProperties = (selector: string): string => {
    const styleElement = document.querySelector(
        `style[data-id="vendex-styles"]`,
    );

    if (styleElement) {
        const styleSheet = (styleElement as HTMLStyleElement)
            .sheet as CSSStyleSheet;
        for (let i = 0; i < styleSheet.cssRules.length; i += 1) {
            const rule = styleSheet.cssRules[i] as CSSStyleRule;
            if (rule.selectorText === selector) {
                styleSheet.deleteRule(i);
                break;
            }
        }
        return styleSheet.ownerNode?.textContent ?? '';
    }
    return '';
};

export const extractFontProperties = (): { [key: string]: string }[] => {
    const styleElement = document.querySelector(
        `style[data-id="vendex-styles"]`,
    );
    const fonts: { [propName: string]: string }[] = [];
    if (styleElement) {
        const styleSheet = (styleElement as HTMLStyleElement)
            .sheet as CSSStyleSheet;
        for (const cssRule of styleSheet.cssRules) {
            if (cssRule instanceof CSSFontFaceRule) {
                const temp = {
                    'font-family':
                        cssRule.style.getPropertyValue('font-family') ?? '',
                    'font-weight':
                        cssRule.style.getPropertyValue('font-weight') ?? '',
                    'font-style':
                        cssRule.style.getPropertyValue('font-style') ?? '',
                    src: cssRule.style.getPropertyValue('src') ?? '',
                };
                fonts.push(temp);
            }
        }
    }
    return fonts;
};

export const setFontProperties = (
    fonts: { [key: string]: string }[],
): string => {
    const styleElement = document.querySelector(
        `style[data-id="vendex-styles"]`,
    );
    let cssString = '';
    if (styleElement) {
        const styleSheet = (styleElement as HTMLStyleElement)
            .sheet as CSSStyleSheet;
        // delete old rules
        for (let i = 0; i < styleSheet.cssRules.length; i += 1) {
            const rule = styleSheet.cssRules[i] as CSSStyleRule;
            if (rule instanceof CSSFontFaceRule) {
                styleSheet.deleteRule(i);
            }
        }
        // add new rules
        fonts.forEach((el) => {
            let fontRule = '';
            for (const key in el) {
                if (el.hasOwnProperty(key)) {
                    fontRule += `${key} : ${el[key]};`;
                }
            }
            fontRule = `@font-face { ${fontRule} }`;
            styleSheet.insertRule(fontRule, styleSheet.cssRules.length);
        });
        for (const cssRule of styleSheet.cssRules) {
            cssString += cssRule.cssText;
        }
    }
    return cssString;
};
