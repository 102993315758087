import React from 'react';
import PortalWrapper from '../../Components/Layout/PortalWrapper';
import Tabs from '../../Components/Tabs';
import InvitationList from './InvitationList';
import RegisteredList from './RegisteredList';

function Representatives() {
    const [activeTab, setActiveTab] = React.useState('REGISTERED');

    return (
        <PortalWrapper>
            <section>
                <div className="bg-white mt-4">
                    <Tabs
                        selected={activeTab}
                        options={[
                            { label: 'Registered Users', value: 'REGISTERED' },
                            { label: 'Invited Users', value: 'INVITED' },
                        ]}
                        onChange={(value: string) => setActiveTab(value)}
                    />
                </div>
                <div className="py-2 md:py-3 lg:py-4">
                    {activeTab === 'INVITED' && <InvitationList />}
                    {activeTab === 'REGISTERED' && <RegisteredList />}
                </div>
            </section>
        </PortalWrapper>
    );
}

export default Representatives;
