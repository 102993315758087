import React from 'react';
import PortalWrapper from '../../Components/Layout/PortalWrapper';
import Tabs from '../../Components/Tabs';
import AccountDetails from './AccountDetails';
import ResetPassword from './ResetPassword';

function UserProfile() {
    const [activeTab, setActiveTab] = React.useState('DETAILS');

    return (
        <PortalWrapper>
            <section>
                <div className="bg-white mt-4">
                    <Tabs
                        selected={activeTab}
                        options={[
                            { label: 'Account Details', value: 'DETAILS' },
                            { label: 'Reset Password', value: 'PASSWORD' },
                        ]}
                        onChange={(value: string) => setActiveTab(value)}
                    />
                </div>
                <div className="py-2 md:py-3 lg:py-4">
                    {activeTab === 'DETAILS' && <AccountDetails />}
                    {activeTab === 'PASSWORD' && <ResetPassword />}
                </div>
            </section>
        </PortalWrapper>
    );
}

export default UserProfile;
