import React from 'react';
import clsx from 'clsx';
import Modal from '../../Components/Modal';
import { LogService } from '../../api';
import DeviceLog, { LOGSEVERITY, LOGTYPE } from '../../Model/DeviceLog';
import { formatDate } from '../../utils/formatText';

interface Prop {
    onClose: () => void;
    selected: string;
}

const errorColor = [
    LOGTYPE.VEND_ERROR,
    LOGTYPE.DEVICE_ERROR,
    LOGTYPE.INVENTORY_ERROR,
];
const warningColor = [LOGTYPE.QR_ERROR];
const successColor = [LOGTYPE.VEND_SUCCESS];
function LogInfo({ onClose, selected }: Prop) {
    const [detail, setDetail] = React.useState<DeviceLog>();
    React.useEffect(() => {
        LogService.GetLogDetail(selected).then((response) =>
            setDetail(response),
        );
    }, []);

    return (
        <Modal
            open
            close={() => onClose()}
            className="max-w-lg overflow-y-auto"
            style={{ maxHeight: '80%' }}
        >
            <h4 className="text-xl font-semibold">LOG DETAIL</h4>
            <br />
            {detail && (
                <>
                    {detail.isTestData && (
                        <p className="bg-secondary font-bold mb-2 p-1 rounded-md text-white">
                            TEST DATA
                        </p>
                    )}
                    <div
                        className="grid gap-3"
                        style={{ gridTemplateColumns: '160px 1fr' }}
                    >
                        <p className="font-semibold">Date Created</p>
                        <p className="text-xs">
                            {formatDate(detail.dateCreated)}
                        </p>
                        <p className="font-semibold">Local Date Created</p>
                        <p className="text-xs">
                            {formatDate(detail.localDateCreated)}
                        </p>
                        <p className="font-semibold">SESSION ID</p>
                        <p className="text-xs">{detail.sessionId}</p>
                        <p className="font-semibold">EXPERIENCE ID</p>
                        <p className="text-xs">{detail.experienceId}</p>
                        <p className="font-semibold">SERIAL NUMBER</p>
                        <p className="text-xs">{detail.serialNumber}</p>
                        <p className="font-semibold">STORE ID</p>
                        <p className="text-xs">{detail.storeId}</p>
                        <p className="font-semibold">SEVERITY</p>
                        <p className="text-xs">
                            {LOGSEVERITY[detail.severity]}
                        </p>
                        <p className="font-semibold">DESCRIPTION</p>
                        <p className="text-xs">{detail.description}</p>
                        <p className="font-semibold">PRODUCT</p>
                        <p className="text-xs">{detail.productId}</p>
                        <p className="font-semibold">SESSION START</p>
                        <p className="text-xs">
                            {detail.sessionStart
                                ? formatDate(detail.sessionStart)
                                : ''}
                        </p>
                        <p className="font-semibold">SESSION END</p>
                        <p className="text-xs">
                            {detail.sessionEnd
                                ? formatDate(detail.sessionEnd)
                                : ''}
                        </p>
                    </div>
                    <div className="flex flex-col gap-2 mt-6">
                        {detail.logs.map((log, index) => (
                            <div
                                key={`${index.toString()}_log`}
                                className={clsx(
                                    'p-3 rounded bg-gray-100',
                                    errorColor.includes(log.type) &&
                                        'bg-red-300',
                                    warningColor.includes(log.type) &&
                                        'bg-yellow-300',
                                    successColor.includes(log.type) &&
                                        'bg-green-300',
                                )}
                            >
                                <div className="flex gap-2">
                                    <p>{LOGTYPE[log.type]}</p>
                                    <p>{formatDate(log.dateCreated)}</p>
                                </div>
                                <p className="text-xs">{log.message}</p>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </Modal>
    );
}

export default LogInfo;
