import React from 'react';
import getStates from '../../utils/getStates';

const states = getStates();

function StateOptions() {
    return (
        <>
            <option value="">SELECT A STATE</option>
            {states.map((el) => (
                <option key={el.label} value={el.value}>
                    {el.label}
                </option>
            ))}
        </>
    );
}

export default StateOptions;
