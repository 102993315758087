import React from 'react';
import QRCode from 'react-qr-code';
import Modal from '../../Components/Modal';
import { DeviceService } from '../../api';
import Button from '../../Components/Button';

interface Props {
    serialNumber: string;
    onClose: () => void;
}
function DeviceQr({ serialNumber, onClose }: Props) {
    const [qrString, setQrString] = React.useState('');

    React.useEffect(() => {
        DeviceService.GenerateAdminQr(serialNumber).then((response) =>
            setQrString(response),
        );
    }, []);

    const downloadQr = () => {
        const svgElement = document.getElementById('ADMIN-QR');
        if (svgElement) {
            const svgString = new XMLSerializer().serializeToString(svgElement);

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
            const DOMURL = window.URL || window.webkitURL || window;
            const img = new Image();
            const svgBlob = new Blob([svgString], {
                type: 'image/svg+xml;charset=utf-8',
            });
            const url = DOMURL.createObjectURL(svgBlob);

            img.onload = () => {
                const padding = 10; // Adjust the padding value as needed

                // Calculate the new canvas dimensions with padding
                const canvasWidth = img.width + 2 * padding;
                const canvasHeight = img.height + 2 * padding;

                canvas.width = canvasWidth;
                canvas.height = canvasHeight;

                // Fill the canvas with a white background
                ctx.fillStyle = '#ffffff'; // Set the background color to white
                ctx.fillRect(0, 0, canvasWidth, canvasHeight);

                // Draw the image with padding
                ctx.drawImage(img, padding, padding);

                // Convert the canvas to a PNG image
                canvas.toBlob((blob) => {
                    // Create a link element and set its attributes
                    const link = document.createElement('a');
                    link.href = DOMURL.createObjectURL(blob as Blob);
                    link.download = `${serialNumber}-QR.png`;

                    // Programmatically click the link to trigger the download
                    link.click();

                    DOMURL.revokeObjectURL(link.href);
                });
            };
            img.src = url;
        }
    };

    return (
        <Modal open close={onClose}>
            <p className="text-xl font-semibold primary">ADMIN QR CODE</p>
            <br />
            <QRCode value={qrString} className="mx-auto" id="ADMIN-QR" />
            <Button
                className="mx-auto block max-w-xs mt-4 bg-primary text-white"
                disabled={qrString.length === 0}
                onClick={downloadQr}
            >
                DOWNLOAD
            </Button>
        </Modal>
    );
}

export default DeviceQr;
