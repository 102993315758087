import React from 'react';
import Button from '../../Components/Button';
import Input from '../../Components/Input';
import { BuilderActionType, BuilderContext } from './state';
import { getStyleProperties } from '../../utils/builderUtils';
import NumberInput from './NumberInput';

type Props = {
    selector: string;
};

function PositionSelector({ selector }: Props) {
    const styleObj = getStyleProperties(selector, [
        'top',
        'left',
        'bottom',
        'right',
        'transform',
    ]);
    const [position, setPosition] = React.useState({ ...styleObj });

    const { dispatch } = React.useContext(BuilderContext);

    const handleChange = (name: string, value: string) => {
        setPosition({ ...position, [name]: value });
        dispatch({
            type: BuilderActionType.UPDATE_CSS,
            payload: { selector, properties: { [name]: value } },
        });
    };

    const handleCenter = (type: 'VERTICAL' | 'HORIZONTAL' | 'BOTH') => {
        let temp = { ...position };
        switch (type) {
            case 'VERTICAL':
                temp = { ...temp, top: '50%', transform: 'translate(0, -50%)' };
                break;
            case 'HORIZONTAL':
                temp = {
                    ...temp,
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                };
                break;
            case 'BOTH':
                temp = {
                    ...temp,
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                };
                break;
            default:
                break;
        }
        setPosition(temp);
        dispatch({
            type: BuilderActionType.UPDATE_CSS,
            payload: { selector, properties: temp },
        });
    };

    return (
        <>
            <p className="font-semibold">Position</p>
            <div className="mt-3 grid grid-cols-3 gap-3">
                <Button
                    onClick={() => handleCenter('HORIZONTAL')}
                    className="bg-primary-light text-white"
                >
                    CENTER X
                </Button>
                <Button
                    onClick={() => handleCenter('VERTICAL')}
                    className="bg-primary-light text-white"
                >
                    CENTER Y
                </Button>
                <Button
                    onClick={() => handleCenter('BOTH')}
                    className="bg-primary-light text-white"
                >
                    CENTER XY
                </Button>
            </div>
            <div className="mt-3 grid grid-cols-4 gap-3">
                <div>
                    <p className="text-xs text-gray-500">Top</p>
                    <NumberInput
                        options={['%', 'px']}
                        defaultOption="%"
                        value={position.top}
                        onChange={handleChange}
                        name="top"
                    />
                </div>
                <div>
                    <p className="text-xs text-gray-500">Left</p>
                    <NumberInput
                        options={['%', 'px']}
                        defaultOption="%"
                        value={position.left}
                        onChange={handleChange}
                        name="left"
                    />
                </div>
                <div>
                    <p className="text-xs text-gray-500">bottom</p>
                    <NumberInput
                        options={['%', 'px']}
                        defaultOption="%"
                        value={position.bottom}
                        onChange={handleChange}
                        name="bottom"
                    />
                </div>
                <div>
                    <p className="text-xs text-gray-500">right</p>
                    <NumberInput
                        options={['%', 'px']}
                        defaultOption="%"
                        value={position.right}
                        onChange={handleChange}
                        name="right"
                    />
                </div>
            </div>
            <div>
                <p className="text-xs text-gray-500">Transform</p>
                <Input
                    value={position.transform}
                    onChange={(e) => handleChange('transform', e.target.value)}
                    style={{ maxWidth: '160px' }}
                />
            </div>
        </>
    );
}

export default PositionSelector;
