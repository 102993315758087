import React from 'react';
import Input from '../../Components/Input';
import Button from '../../Components/Button';
import { emailIsValid } from '../../utils/validations';
import IconButton from '../../Components/IconButton';
import { X } from '../../Components/Icon';
import CompanySelector from '../../Components/CompanySelector';
import Tabs from '../../Components/Tabs';
import { UserRole } from '../../Model/AuthUser';
import { AuthService } from '../../api';

function InviteForm({ handleSubmit }: { handleSubmit: () => void }) {
    const [emails, setEmails] = React.useState<string[]>([]);
    const [selected, setSelected] = React.useState('');
    const [showError, setShowError] = React.useState(false);
    const [companyId, setCompanyId] = React.useState('');
    const [activeTab, setActiveTab] = React.useState('ADMIN');
    const [error, setError] = React.useState('');

    const handleAdd = () => {
        if (emailIsValid(selected)) {
            if (
                emails.findIndex((el) => el === selected.toLowerCase()) === -1
            ) {
                const temp = [...emails];
                temp.push(selected.toLowerCase());
                setEmails(temp);
            }
            setSelected('');
            setShowError(false);
        } else {
            setShowError(true);
        }
    };

    const handleRemove = (index: number) => {
        const temp = [...emails];
        temp.splice(index, 1);
        setEmails(temp);
    };

    const inviteUsers = () => {
        const temp: { email: string; companyId: string; role: UserRole }[] = [];
        emails.forEach((email) =>
            temp.push({
                email,
                companyId,
                role:
                    activeTab === 'ADMIN'
                        ? UserRole.COMPANYADMIN
                        : UserRole.EMPLOYEE,
            }),
        );
        AuthService.InviteUsersApi(temp)
            .then(() => handleSubmit())
            .catch((e) => setError(e.message));
    };

    return (
        <>
            <h2 className="text-xl">INVITE USERS</h2>
            {error.length > 0 && (
                <p className="my-3 text-red-600 text-xs">{error}</p>
            )}
            <CompanySelector
                value={companyId}
                onChange={(value) => setCompanyId(value)}
            />
            {companyId.length > 0 && (
                <>
                    <div className="flex flex-wrap gap-2 mt-4">
                        {emails.map((email, index) => (
                            <div
                                className="shadow-sm rounded-md text-xs border border-grey-400 px-1 cursor-pointer flex gap-1 items-center"
                                key={email}
                                onClick={() => setSelected(email)}
                                role="button"
                                tabIndex={0}
                                onKeyDown={(e) =>
                                    e.key === 'Enter' && setSelected(email)
                                }
                            >
                                {email}

                                <IconButton
                                    className="text-red-600"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handleRemove(index);
                                    }}
                                >
                                    <X className="w-3 h-3" />
                                </IconButton>
                            </div>
                        ))}
                    </div>
                    <Tabs
                        selected={activeTab}
                        options={[
                            { label: 'Invite Admin', value: 'ADMIN' },
                            { label: 'Invite User', value: 'USER' },
                        ]}
                        onChange={(value: string) => {
                            setActiveTab(value);
                            setEmails([]);
                        }}
                    />
                    <div className="relative">
                        <Input
                            value={selected}
                            label="Enter email address"
                            onChange={(e) => setSelected(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' && handleAdd()}
                            className="pr-12"
                            error={showError && !emailIsValid(selected)}
                        />
                        <Button
                            className="absolute top-8 right-2 text-xs py-1 px-2 bg-primary-light text-white"
                            onClick={handleAdd}
                        >
                            Add
                        </Button>
                    </div>
                    <Button
                        className="bg-secondary text-white float-right mt-4"
                        disabled={emails.length === 0}
                        onClick={inviteUsers}
                    >
                        Send Invitation
                    </Button>
                </>
            )}
        </>
    );
}

export default InviteForm;
