import React from 'react';
import Button from '../../Components/Button';
import Input from '../../Components/Input';
import ColorSelector from './ColorSelector';
import { BuilderActionType, BuilderContext } from './state';
import { getStyleProperties } from '../../utils/builderUtils';
import Select from '../../Components/Select';
import NumberInput from './NumberInput';

type Props = {
    selector: string;
    hasAlignment?: boolean;
};

function TypographySelector({ selector, hasAlignment = true }: Props) {
    const styleObj = getStyleProperties(selector, [
        'font-family',
        'font-size',
        'font-weight',
        'font-style',
        'text-align',
        'color',
    ]);
    const [typo, setTypo] = React.useState({ ...styleObj });

    const { dispatch } = React.useContext(BuilderContext);

    const handleChange = (name: string, value: string) => {
        setTypo({ ...typo, [name]: value });
        dispatch({
            type: BuilderActionType.UPDATE_CSS,
            payload: { selector, properties: { ...typo, [name]: value } },
        });
    };

    return (
        <>
            <p className="font-semibold">Typography</p>
            {hasAlignment && (
                <div className="mt-3 grid grid-cols-3 gap-3">
                    <Button
                        onClick={() => handleChange('text-align', 'left')}
                        className="bg-primary-light text-white"
                    >
                        Align Left
                    </Button>
                    <Button
                        onClick={() => handleChange('text-align', 'right')}
                        className="bg-primary-light text-white"
                    >
                        Align Right
                    </Button>
                    <Button
                        onClick={() => handleChange('text-align', 'center')}
                        className="bg-primary-light text-white"
                    >
                        Align Center
                    </Button>
                </div>
            )}
            <div className="mt-3 grid grid-cols-3 gap-3">
                <div>
                    <p className="text-xs text-gray-500">Size</p>
                    <NumberInput
                        options={['px', 'em', 'rem']}
                        defaultOption="px"
                        value={typo['font-size']}
                        onChange={handleChange}
                        name="font-size"
                    />
                </div>
                <div>
                    <p className="text-xs text-gray-500">Weight</p>
                    <Select
                        value={typo['font-weight']}
                        onChange={(e) =>
                            handleChange('font-weight', e.target.value)
                        }
                    >
                        <option value="">SELECT WEIGHT</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                        <option value={300}>300</option>
                        <option value={400}>400</option>
                        <option value={500}>500</option>
                        <option value={600}>600</option>
                        <option value={700}>700</option>
                        <option value={800}>800</option>
                        <option value={900}>900</option>
                    </Select>
                </div>
                <div>
                    <p className="text-xs text-gray-500">Style</p>
                    <Select
                        value={typo['font-style']}
                        onChange={(e) =>
                            handleChange('font-style', e.target.value)
                        }
                    >
                        <option value="">SELECT STYLE</option>
                        <option value="italic">italic</option>
                        <option value="normal">normal</option>
                    </Select>
                </div>
                <div>
                    <p className="text-xs text-gray-500">Font Family</p>
                    <Input
                        value={typo['font-family']}
                        onChange={(e) =>
                            handleChange('font-family', e.target.value)
                        }
                    />
                </div>
            </div>
            <div className="flex gap-3 my-6">
                <p className="text-xs text-gray-500">Color</p>
                <ColorSelector
                    color={typo.color}
                    onChange={(value) => handleChange('color', value)}
                />
            </div>
        </>
    );
}

export default TypographySelector;
