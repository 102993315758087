import React from 'react';
import { CellProps, useSortBy, useTable } from 'react-table';
import InvitedUser from '../../Model/InvitedUser';
import InvitationQuickActions from './InvitationQuickActions';
import SortingIcon from '../../Components/GridComponents/SortingIcon';
import { AuthService } from '../../api';
import useAuth from '../../Components/LayoutProvider/AuthProvider/useAuth';
import { AuthUser, ROLESTRING, UserRole } from '../../Model/AuthUser';
import Button from '../../Components/Button';
import { Mail } from '../../Components/Icon';
import Modal from '../../Components/Modal';
import InviteForm from './InviteForm';
import { formatDate } from '../../utils/formatText';

function InvitationList() {
    const [data, setData] = React.useState<InvitedUser[]>([]);
    const { user } = useAuth<AuthUser>();
    const [open, setOpen] = React.useState(false);

    const getData = () => {
        AuthService.GetInvitedUsers().then((response) => setData(response));
    };

    const handleSubmit = () => {
        getData();
        setOpen(false);
    };

    React.useEffect(() => {
        getData();
    }, []);

    const memoizedColumns: any = React.useMemo(() => {
        const baseColumns = [
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Role',
                accessor: 'role',
                Cell: ({ value }: CellProps<InvitedUser, UserRole>) => {
                    return ROLESTRING[value];
                },
            },
            {
                Header: 'Date Created',
                accessor: 'dateCreated',
                Cell: ({ value }: CellProps<InvitedUser, string>) => {
                    return formatDate(value);
                },
            },
            {
                Header: ' ',
                accessor: '',
                Cell: ({ row }: CellProps<InvitedUser>) => {
                    return InvitationQuickActions({
                        invite: row.original,
                        refreshData: getData,
                    });
                },
            },
        ];

        if (user.role === UserRole.SUPERADMIN) {
            baseColumns.splice(0, 0, {
                Header: 'Company',
                accessor: 'companyName',
            });
        }

        return baseColumns;
    }, [user.role]);

    // Memoize data
    const memoizedData = React.useMemo(() => data, [data]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns: memoizedColumns, data: memoizedData }, useSortBy);

    return (
        <>
            <div className="bg-gray-200 shadow-md flex justify-end p-3 border-b border-gray-200">
                <Button
                    className="bg-secondary text-white flex gap-2 justify-center items-center"
                    onClick={() => setOpen(!open)}
                >
                    <Mail /> INVITE USERS
                </Button>
            </div>
            <table {...getTableProps()} className="w-full">
                <thead className="shadow-md">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th
                                    className="bg-white/50 border-t border-b border-gray-200 p-4 hover:cursor-pointer font-bold uppercase"
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps(),
                                    )}
                                >
                                    {column.render('Header')}
                                    <SortingIcon
                                        isSorted={column.isSorted}
                                        isSortedDesc={column.isSortedDesc}
                                    />
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody className="bg-white" {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className="border-b border-gray-200"
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            className="text-center py-3 border-r border-r-gray-200 px-2"
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <Modal open={open} close={() => setOpen(false)} className="min-h-0">
                <InviteForm handleSubmit={handleSubmit} />
            </Modal>
        </>
    );
}

export default InvitationList;
