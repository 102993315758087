import NavRoute from './NavRoute';
import TemplateRoute from './TemplateRoute';

function getNavigationItems(
    routes: TemplateRoute[],
    role = 'visitor',
): NavRoute[] {
    const navRoutes: NavRoute[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const route of routes) {
        if (
            (route.navigation && !route.authRoles) ||
            (route.navigation && route.authRoles?.includes(role))
        ) {
            navRoutes.push({
                path: route.path,
                Icon: route.Icon,
                label: route.label ?? '',
                helperText: route.helperText,
            });
        }
    }
    return navRoutes;
}

export default getNavigationItems;
