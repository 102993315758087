import React from 'react';
import { Loader } from '../Icon';

function PageLoader() {
    return (
        <div className="w-full h-full flex justify-center items-center bg-gradient-to-r from-primary-light to-primary">
            <Loader className="w-16 h-16 text-white" />
        </div>
    );
}

export default PageLoader;
