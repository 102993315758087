import React from 'react';
import { SessionAnalytics } from '../../Model/Analytics';

interface Props {
    data: SessionAnalytics;
}
function UserTurnover({ data }: Props) {
    return (
        <div className="bg-white/50 rounded-lg border border-gray-200 p-5 w-full  md:max-w-xs">
            <p className="text-lg text-primary font-medium">STATS</p>
            <hr className="py-1" />
            <div className="flex flex-col gap-2">
                <div className="flex items-center">
                    <h1 className="w-20 text-2xl font-thin text-blue-600">
                        {data.total}
                    </h1>
                    <p className="font-bold">SESSION</p>
                </div>
                <div className="flex items-center">
                    <h1 className="w-20 text-2xl font-thin text-green-600">
                        {`${data.success}`}
                    </h1>
                    <p className="font-bold">SUCCESS</p>
                </div>
                <div className="flex items-center">
                    <h1 className="w-20 text-2xl font-thin text-blue-600">
                        {`${((data.success / data.total) * 100).toFixed(1)}%`}
                    </h1>
                    <p className="font-bold">CONVERSION RATE</p>
                </div>
            </div>
        </div>
    );
}

export default UserTurnover;
