import React from 'react';
import { PlusCircle } from '../../Components/Icon';
import PortalWrapper from '../../Components/Layout/PortalWrapper';
import CompanyCard from './CompanyCard';
import Company from '../../Model/Company';
import CompanyModal from './CompanyModal';
import { GetCompanies } from '../../api/companyService';

function Companies() {
    const [openModal, setOpenModal] = React.useState(false);
    const [companies, setCompanies] = React.useState<Company[]>();

    const getData = () => {
        GetCompanies().then((res) => setCompanies(res));
    };

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <PortalWrapper>
            <div>
                <div className="flex flex-wrap gap-4 lg:gap-5 xl:gap-6 justify-center lg:justify-start">
                    <div
                        className="relative shadow-md w-60 p-4 bg-white rounded-md flex flex-col justify-center items-center hover:scale-105"
                        role="button"
                        onClick={() => setOpenModal(true)}
                        onKeyDown={(e) =>
                            e.key === 'Enter' && setOpenModal(true)
                        }
                        tabIndex={0}
                    >
                        <PlusCircle />
                        <p className="text-align-center mt-4">
                            CREATE NEW COMAPNY
                        </p>
                    </div>
                    {companies?.map((el) => (
                        <CompanyCard data={el} key={el.id} />
                    ))}
                </div>
            </div>
            {openModal && (
                <CompanyModal
                    onClose={() => {
                        setOpenModal(false);
                    }}
                    refreshData={getData}
                />
            )}
        </PortalWrapper>
    );
}

export default Companies;
