import React from 'react';
import { CellProps, useSortBy, useTable } from 'react-table';
import SortingIcon from '../../Components/GridComponents/SortingIcon';
import UserQuickActions from './UserQuickActions';
import { AuthService } from '../../api';
import { User } from '../../Model/User';
import { formatDate } from '../../utils/formatText';
import useAuth from '../../Components/LayoutProvider/AuthProvider/useAuth';
import { AuthUser, UserRole } from '../../Model/AuthUser';

function RegisteredList() {
    const [data, setData] = React.useState<User[]>([]);
    const { user } = useAuth<AuthUser>();

    const getData = () => {
        AuthService.GetRegisteredUsers().then((response) => setData(response));
    };
    React.useEffect(() => {
        getData();
    }, []);

    const memoizedColumns: any = React.useMemo(() => {
        const baseColumns = [
            {
                Header: 'First Name',
                accessor: 'firstName',
            },
            {
                Header: 'Last Name',
                accessor: 'lastName',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Date Created',
                accessor: 'dateCreated',
                Cell: ({ value }: CellProps<User, string>) => {
                    return formatDate(value);
                },
            },
            {
                Header: 'Status',
                accessor: 'isDisabled',
                Cell: ({ value }: CellProps<User, boolean>) => {
                    return value ? 'Disabled' : 'Enabled';
                },
            },
            {
                Header: ' ',
                accessor: '',
                Cell: ({ row }: CellProps<User>) => {
                    return UserQuickActions({
                        user: row.original,
                        refreshData: getData,
                    });
                },
            },
        ];

        if (user.role === UserRole.SUPERADMIN) {
            baseColumns.splice(0, 0, {
                Header: 'Company',
                accessor: 'companyName',
            });
        }

        return baseColumns;
    }, [user.role]);

    // Memoize data
    const memoizedData = React.useMemo(() => data, [data]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns: memoizedColumns, data: memoizedData }, useSortBy);

    return (
        <table {...getTableProps()} className="w-full">
            <thead className="shadow-md">
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                            <th
                                className="bg-white/50 border-t border-b border-gray-200 p-4 hover:cursor-pointer font-bold uppercase"
                                {...column.getHeaderProps(
                                    column.getSortByToggleProps(),
                                )}
                            >
                                {column.render('Header')}
                                <SortingIcon
                                    isSorted={column.isSorted}
                                    isSortedDesc={column.isSortedDesc}
                                />
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody className="bg-white" {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr
                            {...row.getRowProps()}
                            className="border-b border-gray-200"
                        >
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        className="text-center py-3 border-r border-r-gray-200 px-2"
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default RegisteredList;
