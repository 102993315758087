import React from 'react';
import Input from '../../../Components/Input';
import ActionSelector from '../ActionSelector';
import DimensionSelector from '../DimensionSelector';
import PositionSelector from '../PositionSelector';
import { BuilderActionType, BuilderContext } from '../state';
import VendexElement, { VendexQrMeta } from '../../../Model/VendexElement';
import VendexAction from '../../../Model/VendexAction';

type Props = {
    element: VendexElement;
};

function QrDetail({ element }: Props) {
    const data = element.metaData as VendexQrMeta;

    const { dispatch } = React.useContext(BuilderContext);

    const handleChange = (value: VendexElement) => {
        dispatch({
            type: BuilderActionType.UPDATE_ELEMENT,
            payload: value,
        });
    };

    const handleDataChange = (name: string, value: string | number) => {
        handleChange({
            ...element,
            metaData: { ...data, [name]: value },
        });
    };

    const handleActionChange = (actions: VendexAction[]) => {
        handleChange({
            ...element,
            actions,
        });
    };

    return (
        <>
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">Type</p>
                <select
                    onChange={(e) => {
                        handleDataChange('type', e.target.value);
                    }}
                    value={data.type}
                >
                    <option value="STATIC">STATIC</option>
                    <option value="DYNAMIC">DYNAMIC</option>
                </select>
            </div>
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">Value</p>
                <Input
                    value={data.content}
                    onChange={(e) =>
                        handleDataChange('content', e.target.value)
                    }
                />
            </div>
            <br />
            <PositionSelector selector={`#${element.id}`} />
            <DimensionSelector selector={`#${element.id}`} />
            <ActionSelector
                actions={element.actions}
                onChange={handleActionChange}
            />
        </>
    );
}

export default QrDetail;
