function downloadFile(
    responsePayload: Blob,
    filename: string,
): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
        const url = URL.createObjectURL(responsePayload);

        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.style.display = 'none';

        document.body.appendChild(link);

        link.addEventListener('click', () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                resolve(true);
            }, 100);
        });

        link.click();

        document.body.removeChild(link);
    });
}

export default downloadFile;
