import React, { ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

function Wrapper({ children }: Props) {
    return (
        <main className="w-full h-full flex bg-gradient-to-r from-primary-light to-primary">
            <div className="hidden lg:block flex-grow py-20 px-8">
                <div className="max-w-2xl mx-auto w-100">
                    <img
                        src={`${process.env.PUBLIC_URL}/logo.png`}
                        className="w-120 -ml-2"
                        alt="Incharged Logo"
                    />
                    <h3 className="font-secondary font-semibold text-4xl lg:text-5xl mt-8 uppercase text-white">
                        You create the experience
                    </h3>
                    <h6 className="font-secondary font-semibold text-3xl lg:text-4xl text-secondary mt-8 uppercase">
                        We create the event technology
                        <br />
                        to make it memorable.
                    </h6>
                </div>
            </div>
            <div className="w-full flex items-center p-4 lg:w-1/3 lg:items-start lg:py-20 lg:px-8 lg:shadow-lg lg:bg-white lg:max-w-md">
                <div className="max-w-sm mx-auto bg-slate-50 shadow-lg p-8 w-full rounded-lg lg:rounded-none lg:bg-transparent lg:shadow-none lg:p-0">
                    <div className="flex gap-2 mb-4 items-center">
                        <img
                            src={`${process.env.PUBLIC_URL}/vendx-full.png`}
                            className="w-8 h-5"
                            alt="Vendex Logo"
                        />
                        <h1 className="text-primary text-2xl font-bold">
                            VENDEX
                        </h1>
                    </div>
                    {children}
                </div>
            </div>
        </main>
    );
}

export default Wrapper;
