import React from 'react';
import { ProductAnalytics } from '../../Model/Analytics';

interface Props {
    data: ProductAnalytics[];
}

function ProductChart({ data }: Props) {
    return (
        <div
            className="bg-white/50 rounded-lg border border-gray-200 p-5 flex-grow lg:max-w-md"
            style={{ minWidth: '240px' }}
        >
            <p className="text-lg text-primary font-medium">PRODUCTS</p>
            <hr className="py-1" />
            <div className="flex justify-between gap-2 border-b border-b-gray-200 font-semibold mt-3 pb-1">
                <p>NAME</p>
                <p>TOTAL VEND</p>
            </div>
            <div
                className="overflow-y-auto px-5 -mx-5"
                style={{ maxHeight: '360px' }}
            >
                {data.map((el) => (
                    <div
                        key={el.id}
                        className="flex justify-between gap-2 border-b border-b-gray-200 py-1"
                    >
                        <p>{el.name}</p>
                        <p>{el.numberOfVend}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ProductChart;
