const formatPhoneNumber = (phoneNumber: unknown): string => {
    if (typeof phoneNumber !== 'string') {
        return 'Invalid number';
    }
    // eslint-disable-next-line prefer-template
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(
            '',
        );
    }
    return 'Invalid number';
};

const formatLocation = (
    addressline1: string,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    addressLine2 = '',
    city: string,
    state: string,
    country: string,
    zip: string,
) => {
    if (addressline1.length > 1) {
        return [addressline1, addressLine2, city, state, country, zip]
            .join(', ')
            .replace(/(, ){2,}/g, ', ');
    }
    return [city, state, country, zip].join(', ').replace(/(, ){2,}/g, ', ');
};

function formatDateForDateTimeInput(dateString: string | Date): string {
    const date = new Date(dateString);

    if (Number.isNaN(date.getTime())) {
        return '';
    }

    const year = date.getFullYear().toString().padStart(4, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

    return formattedDate;
}

function formatDate(dateString: string): string {
    if (typeof dateString !== 'string') return '';
    const date = new Date(dateString);
    if (Number.isNaN(date)) {
        return '';
    }
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

function formatTime(dateString: string): string {
    const date = new Date(dateString);
    const localDate = new Date(date.toLocaleString());

    const hour = localDate.getHours();
    const minute = localDate.getMinutes();
    const second = localDate.getSeconds();
    const amPm = hour < 12 ? 'AM' : 'PM';

    const formattedHour = (hour % 12 || 12).toString().padStart(2, '0');
    const formattedMinute = minute.toString().padStart(2, '0');
    const formattedSecond = second.toString().padStart(2, '0');

    return `${formattedHour}:${formattedMinute}:${formattedSecond} ${amPm}`;
}

export {
    formatLocation,
    formatPhoneNumber,
    formatDateForDateTimeInput,
    formatDate,
    formatTime,
};
