import React from 'react';
import { Link } from 'react-router-dom';
import { SendForgotPasswordLink } from '../../api/authService';
import Button from '../../Components/Button';
import Input from '../../Components/Input';
import Wrapper from '../../Components/Layout/Wrapper';
import { emailIsValid } from '../../utils/validations';

function ForgotPassword() {
    const [showError, setShowError] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [success, setSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [reqError, setReqError] = React.useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        if (emailIsValid(email)) {
            SendForgotPasswordLink(email)
                .then(() => {
                    setSuccess(true);
                })
                .catch((error) => setReqError(error.message))
                .finally(() => setLoading(false));
        } else {
            setShowError(true);
        }
    };

    return (
        <Wrapper>
            <h6 className="text-xl font-semibold">FORGOT PASSWORD</h6>
            {reqError.length > 0 && (
                <p className="text-red-600 text-sm mt-4">{reqError}</p>
            )}
            {!success ? (
                <>
                    <p className="text-sm text-gray-600 mt-4">
                        Enter the email associated with your account and{' '}
                        {`we'll`} send an email with instructions to reset your
                        password.
                    </p>
                    <form
                        className="mt-4"
                        onSubmit={handleSubmit}
                        onClick={() => setReqError('')}
                        role="none"
                    >
                        <Input
                            label="Email Address"
                            name="Email Address"
                            onChange={(e) => setEmail(e.target.value)}
                            error={showError}
                            required
                        />
                        <div className="flex justify-between gap-2">
                            <Link
                                to="/login"
                                className="italic text-gray-600 underline"
                            >
                                Back to Login
                            </Link>
                            <Button
                                type="submit"
                                className="bg-primary text-white mt-4 float-right"
                                loading={loading}
                            >
                                SUBMIT
                            </Button>
                        </div>
                    </form>
                </>
            ) : (
                <>
                    <p className="text-sm text-gray-600 mt-4">
                        Password recovery instructions have been sent to your
                        email.
                    </p>
                    <p className="text-sm text-gray-600 mt-4">
                        Did not receive the email? Check your spam filter,
                        or&nbsp;
                        <button
                            onClick={() => {
                                setEmail('');
                                setSuccess(false);
                            }}
                            type="button"
                            className="text-blue-600 underline"
                        >
                            try another email address
                        </button>
                    </p>
                    <Link
                        to="/login"
                        className="italic text-gray-600 underline text-center mt-4 mx-auto block"
                    >
                        Back to Login
                    </Link>
                </>
            )}
        </Wrapper>
    );
}

export default ForgotPassword;
