import React from 'react';
import { BuilderActionType, BuilderContext } from './state';
import ElementDetail from './BuilderElementDetails/ElementDetail';
import IconButton from '../../Components/IconButton';
import { Trash2 } from '../../Components/Icon';
import Expandable from '../../Components/Expandable';

function PageElements() {
    const { previewPage } = React.useContext(BuilderContext).state;
    const { dispatch } = React.useContext(BuilderContext);
    const [openElement, setOpenElement] = React.useState('');
    const [noOfEl, setNoOfEl] = React.useState(0);

    const handleSelection = () => {
        const oldPreview = document.getElementsByClassName('preview-element');
        if (oldPreview && oldPreview.length > 0) {
            oldPreview[0].classList.remove(
                'preview-element',
                'outline-dashed',
                'outline-2',
                'outline-red-600',
                'outline-offset-2',
            );
        }
        const newPreview = document.getElementById(openElement);
        if (newPreview) {
            newPreview.classList.add(
                'preview-element',
                'outline-dashed',
                'outline-2',
                'outline-red-600',
                'outline-offset-1',
            );
        }
    };

    React.useEffect(() => {
        if (previewPage && previewPage.elements.length !== noOfEl) {
            setNoOfEl(previewPage.elements.length);
        }
        setTimeout(() => handleSelection(), 1000);
        handleSelection();
    }, [previewPage]);

    React.useEffect(() => {
        setOpenElement('');
    }, [noOfEl]);

    React.useEffect(() => {
        handleSelection();
    }, [openElement]);

    const handleDelete = (id: string) => {
        dispatch({
            type: BuilderActionType.DELETE_ELEMENT,
            payload: id,
        });
    };

    const handleToggle = (elementId: string) => {
        if (elementId === openElement) {
            setOpenElement('');
        } else {
            setOpenElement(elementId);
        }
    };
    if (!previewPage) {
        return null;
    }
    return (
        <>
            {previewPage.elements.map((el) => (
                <Expandable
                    key={el.id}
                    open={openElement === el.id}
                    onToggle={() => handleToggle(el.id)}
                    header={
                        <div>
                            <p>{el.type}</p>
                            <p className="text-xs text-gray-500">#{el.id}</p>
                            {el.id !== 'DEFAULT_SYSTEM_MESSAGE' && (
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete(el.id);
                                    }}
                                    className="text-red-600 absolute right-12 top-3"
                                >
                                    <Trash2 />
                                </IconButton>
                            )}
                        </div>
                    }
                >
                    <ElementDetail element={el} />
                </Expandable>
            ))}
        </>
    );
}

export default PageElements;
