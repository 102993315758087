import React from 'react';
import Editor from '@monaco-editor/react';
import { BuilderActionType, BuilderContext } from './state';
import { PlayCircle } from '../../Components/Icon';
import Button from '../../Components/Button';

function CodeEditor() {
    const { pages, styles, script, experience } =
        React.useContext(BuilderContext).state;

    const editorRef = React.useRef<any>(null);
    const { dispatch } = React.useContext(BuilderContext);

    const getValue = () => {
        const temp = {
            pages,
            styles,
            script,
        };

        return JSON.stringify(temp, null, '\t');
    };

    const handleCompile = () => {
        if (editorRef.current) {
            const val = editorRef.current.getValue() as string;
            dispatch({
                type: BuilderActionType.SET_EXPERIENCE,
                payload: { ...experience, ...JSON.parse(val) },
            });
        }
    };

    return (
        <div className="w-3/5 h-full">
            <Button
                onClick={handleCompile}
                className="fixed bottom-4 left-1/3 -translate-x-1/3 z-20 flex gap-2 bg-primary-light text-white"
            >
                <span>Compile</span> <PlayCircle />
            </Button>
            <Editor
                theme="chrome-dev-tools"
                width="100%"
                height="100%"
                defaultLanguage="json"
                defaultValue={getValue()}
                // eslint-disable-next-line no-return-assign
                onMount={(ref: any) => (editorRef.current = ref)}
            />
        </div>
    );
}

export default CodeEditor;
