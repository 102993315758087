export type AuthUser = {
    accessToken: string;
    id: string;
    companyId: string | null;
    dateCreated: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    role: UserRole;
};

export enum UserRole {
    SUPERADMIN = 0,
    COMPANYADMIN = 1,
    EMPLOYEE = 2,
}

export const ROLESTRING = {
    [UserRole.SUPERADMIN]: 'system admin',
    [UserRole.COMPANYADMIN]: 'admin',
    [UserRole.EMPLOYEE]: 'user',
};
