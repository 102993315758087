import React from 'react';
import Button from '../../Components/Button';
import StoreForm from './StoreForm';
import { CompanyService } from '../../api';
import Store from '../../Model/Store';
import StoreCard from './StoreCard';
import { useConfirmation } from '../../Components/ConfirmationDialog';
import { useNotification } from '../../Components/Notification';

function Stores({ companyId }: { companyId: string }) {
    const [stores, setStores] = React.useState<Store[]>([]);
    const [selected, setSelected] = React.useState<Store>();
    const confirmation = useConfirmation();
    const notification = useNotification();

    const getData = () => {
        CompanyService.GetStores(companyId).then((response) =>
            setStores(response),
        );
    };

    React.useEffect(() => {
        getData();
    }, []);

    const handleDelete = (id: string) => {
        CompanyService.DeleteStore(id)
            .then(() => getData())
            .catch(() => {
                notification({
                    type: 'error',
                    message: 'Error deleting store',
                });
            });
    };

    const deleteStore = (store: Store) => {
        confirmation({
            message: `Deleting a store might affect devices associated with that store. Are you sure you want to delete store ${store.name}?`,
            onConfirm: () => handleDelete(store.id as string),
            title: 'Delete Store',
        });
    };

    return (
        <>
            <Button
                className="w-full bg-secondary text-white"
                onClick={() =>
                    setSelected({
                        companyId,
                        name: '',
                        timeZone: '',
                    })
                }
            >
                ADD VENUE
            </Button>
            <div className="flex flex-wrap mt-4 gap-4 items-start justify-start">
                {stores.map((store) => (
                    <StoreCard
                        key={store.id as string}
                        store={store}
                        editStore={() => setSelected(store)}
                        deleteStore={() => deleteStore(store)}
                    />
                ))}
            </div>
            {selected && (
                <StoreForm
                    onCancel={() => setSelected(undefined)}
                    store={selected}
                    refreshData={getData}
                />
            )}
        </>
    );
}

export default Stores;
