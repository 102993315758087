import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSortBy, useTable, CellProps } from 'react-table';
import Experience from '../../Model/Experience';
import SortingIcon from '../../Components/GridComponents/SortingIcon';
import ExperienceActions from './ExperienceActions';
import useAuth from '../../Components/LayoutProvider/AuthProvider/useAuth';
import { AuthUser, UserRole } from '../../Model/AuthUser';
import { formatDate } from '../../utils/formatText';

type Props = {
    experienceData: Experience[];
    onClose: () => void;
    refreshData: () => void;
    selectExperience:(exp:Experience)=>void
};

function ExperienceList({
    experienceData,
    onClose,
    refreshData,
    selectExperience,
}: Props): JSX.Element {
    const navigate = useNavigate();
    const { user } = useAuth<AuthUser>();
    const memoizedData = React.useMemo(() => experienceData, [experienceData]);

    const memoizedColumns: any = React.useMemo(() => {
        const baseColumns = [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Latest version',
                accessor: 'version',
            },
            {
                Header: 'Date Updated',
                accessor: 'dateUpdated',
                Cell: ({ value }: CellProps<Experience, string>) => {
                    return formatDate(value);
                },
            },
            {
                Header: ' ',
                accessor: '',
                Cell: ({ row }: CellProps<Experience>) =>
                    ExperienceActions({
                        onClose,
                        refreshData,
                        selectExperience,
                        exp: row.original,
                    }),
            },
        ];
        if (user.role === UserRole.SUPERADMIN) {
            baseColumns.splice(0, 0, {
                Header: 'Company',
                accessor: 'companyName',
            });
        }
        return baseColumns;
    }, [user.role]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns: memoizedColumns, data: memoizedData }, useSortBy);

    return (
        <table {...getTableProps()} className="rounded w-full">
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                            <th
                                className="bg-white/50 border-t border-b border-gray-200 p-4 hover:cursor-pointer font-bold uppercase"
                                {...column.getHeaderProps(
                                    column.getSortByToggleProps(),
                                )}
                            >
                                {column.render('Header')}
                                <span>
                                    <SortingIcon
                                        isSorted={column.isSorted}
                                        isSortedDesc={column.isSortedDesc}
                                    />
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody className="bg-white border-2px" {...getTableBodyProps()}>
                {rows.map((row: any) => {
                    prepareRow(row);
                    return (
                        <tr
                            {...row.getRowProps()}
                            className="hover:bg-gray-100 cursor-pointer border-b border-gray-200"
                            onClick={() => {
                                navigate(`/experiences/${row.original.id}`);
                            }}
                        >
                            {row.cells.map((cell: any) => {
                                return (
                                    <td
                                        className="text-center py-3 border-r border-r-gray-200 px-2"
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default ExperienceList;
