/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from 'react';
import Input from '../../Components/Input';
import { BuilderActionType, BuilderContext } from './state';
import BackgroundSelector from './BackgroundSelector';
import { PageType } from '../../Model/PageData';
import { Info } from '../../Components/Icon';

const PAGE_INFO: { [key: string]: string } = {
    [PageType.SCREEN_DEFAULT]:
        'All system messages will be redirected to this page if no custom pages are provided. Donot add any interactions or timers on this page. This is usually a terminal error state. For better experience, please create pages for the various use cases defined in the "add page" dialog.',
    [PageType.SCREEN_IDLE]:
        'This will be the idle screen when the machine is stocked and ready to be used.',
    [PageType.SCREEN_VENDING]:
        'Users will automatically be redirected from this page to a success or error screen based on machine response.',
    [PageType.SCREEN_VEND_SUCCESS]:
        'Users will automatically be redirected to an idle screen once the machine is ready for the next session. NOTE: this is not the case with legacy build.',
    [PageType.SCREEN_DEVICE_OUT_OF_STOCK]:
        'This screen will be up if there is no inventory left on the device. Machine needs to be restocked before the device is available for vend sessions.',
};

function PageDetails() {
    const { previewPage } = React.useContext(BuilderContext).state;
    const { dispatch } = React.useContext(BuilderContext);

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: BuilderActionType.SET_PAGE_NAME,
            payload: e.target.value,
        });
    };
    if (!previewPage) return null;

    return (
        <>
            <p>
                <strong>PAGE IDENTIFIER: </strong>
                {previewPage.id}
            </p>
            <p className="text-xs text-gray-400">
                use #{previewPage.id} as selector for custom css
            </p>

            <p className="text-xs text-gray-400">
                For pages that do not redirect automatically, Please add a timer element and assign a change page action.
            </p>

            {PAGE_INFO[previewPage.id] && (
                <div className="flex gap-3 mt-3 items-center">
                    <Info className="text-blue-600 w-8 h-8" />
                    <p>{PAGE_INFO[previewPage.id]}</p>
                </div>
            )}
            <Input
                name="name"
                label="PAGE NAME"
                value={previewPage.name}
                className="w-full"
                onChange={handleNameChange}
                autoFocus
            />
            <div className="my-4">
                <BackgroundSelector selector={`#${previewPage.id}`} />
            </div>
        </>
    );
}

export default PageDetails;
