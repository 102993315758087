import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader, LogOut } from '../../Components/Icon';
import useAuth from '../../Components/LayoutProvider/AuthProvider/useAuth';
import { AuthUser } from '../../Model/AuthUser';

function Logout() {
    const navigate = useNavigate();
    const { setUser } = useAuth<AuthUser>();

    React.useEffect(() => {
        localStorage.removeItem('vendx-token');
        setUser({ role: 'visitor' });
        setTimeout(() => {
            navigate(`/login`);
        }, 1000);
    });
    return (
        <div className="h-screen w-screen bg-primary-light flex flex-col justify-center items-center gap-4">
            <LogOut className="w-24 h-24 text-white" />
            <div className="flex gap-2 items-center">
                <Loader className="w-12 h-12 text-white" />
                <h1 className="text-2xl font-bold text-secondary">
                    LOGGING OUT
                </h1>
            </div>
        </div>
    );
}

export default Logout;
