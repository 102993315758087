import React from 'react';
import IconButton from '../../Components/IconButton';
import { Send, Trash2 } from '../../Components/Icon';
import InvitedUser from '../../Model/InvitedUser';
import { AuthService } from '../../api';
import { useNotification } from '../../Components/Notification';
import Tooltip from '../../Components/Tooltip';

interface Props {
    invite: InvitedUser;
    refreshData: () => void;
}

function InvitationQuickActions({ invite, refreshData }: Props) {
    const notification = useNotification();

    const handleDelete = () => {
        AuthService.DeleteInvitation(invite.id).then(() => {
            notification({
                type: 'success',
                duration: 6000,
                message: `invitation for ${invite.email} was successfully deleted.`,
            });
            refreshData();
        });
    };

    const handleResend = () => {
        AuthService.InviteUsersApi([invite]).then(() =>
            notification({
                type: 'success',
                duration: 6000,
                message: `invitation for ${invite.email} was successfully resend.`,
            }),
        );
    };
    return (
        <div
            className="flex gap-3"
            role="none"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <IconButton
                onClick={handleDelete}
                btnSize="small"
                className="text-red-600"
            >
                <Trash2 />
            </IconButton>
            <Tooltip text="resend invite">
                <IconButton
                    onClick={handleResend}
                    btnSize="small"
                    className="text-blue-600"
                >
                    <Send />
                </IconButton>
            </Tooltip>
        </div>
    );
}

export default InvitationQuickActions;
