import { SignInWithTokenApi } from './api/authService';
import {
    Download,
    FileText,
    Home,
    Layout,
    LogOut,
    ShoppingBag,
    Tablet,
    Trello,
    Users,
    User,
} from './Components/Icon';
import TemplateConfig from './Components/LayoutProvider/TemplateConfig';
import PageLoader from './Components/PageLoader';
import Companies from './Containers/Companies';
import Dashboard from './Containers/Dashboard';
import ForgotPassword from './Containers/ForgotPassword';
import Login from './Containers/Login';
import PageNotFound from './Containers/PageNotFound';
import Register from './Containers/Register';
import ResetPassword from './Containers/ResetPassword';
import Experiences from './Containers/Experiences';
import ExperienceDetail from './Containers/Experiences/ExperienceDetail';
import Products from './Containers/Products';
import Representatives from './Containers/Representatives';
import Builder from './Containers/Builder';
import Logs from './Containers/Logs';
import Devices from './Containers/Devices';
import DeviceDetail from './Containers/Devices/DeviceDetail';
import Logout from './Containers/Logout';
import { UserRole } from './Model/AuthUser';
import Applications from './Containers/Applications';
import UserProfile from './Containers/UserProfile';
import CompanyDetail from './Containers/Companies/CompanyDetail';

const config: TemplateConfig = {
    routes: [
        {
            label: 'Dashboard',
            path: '/',
            Icon: Home,
            Element: Dashboard,
            authRoles: [
                UserRole.COMPANYADMIN,
                UserRole.SUPERADMIN,
                UserRole.EMPLOYEE,
            ],
            navigation: true,
        },
        {
            label: 'Devices',
            path: '/devices',
            Icon: Tablet,
            Element: Devices,
            navigation: true,
            authRoles: [
                UserRole.COMPANYADMIN,
                UserRole.SUPERADMIN,
                UserRole.EMPLOYEE,
            ],
        },
        {
            label: 'Devices',
            path: '/devices/:id',
            Icon: Tablet,
            Element: DeviceDetail,
            authRoles: [
                UserRole.COMPANYADMIN,
                UserRole.SUPERADMIN,
                UserRole.EMPLOYEE,
            ],
        },
        {
            label: 'Products',
            path: '/products',
            Icon: ShoppingBag,
            Element: Products,
            navigation: true,
            authRoles: [
                UserRole.COMPANYADMIN,
                UserRole.SUPERADMIN,
                UserRole.EMPLOYEE,
            ],
        },
        {
            label: 'Logs',
            path: '/logs',
            Icon: FileText,
            Element: Logs,
            navigation: true,
            authRoles: [UserRole.SUPERADMIN],
        },
        {
            label: 'Companies',
            path: '/companies',
            Icon: Layout,
            Element: Companies,
            helperText: 'Admin',
            navigation: true,
            authRoles: [UserRole.SUPERADMIN],
        },
        {
            label: 'Company',
            path: '/company',
            Icon: Layout,
            Element: CompanyDetail,
            helperText: 'Admin',
            navigation: true,
            authRoles: [UserRole.COMPANYADMIN],
        },
        {
            label: 'Companies',
            path: '/companies/:id',
            Element: CompanyDetail,
            authRoles: [UserRole.SUPERADMIN],
        },
        {
            label: 'Users',
            path: '/users',
            Icon: Users,
            Element: Representatives,
            navigation: true,
            authRoles: [UserRole.SUPERADMIN, UserRole.COMPANYADMIN],
        },
        {
            label: 'Experience',
            path: '/experiences',
            Icon: Trello,
            Element: Experiences,
            navigation: true,
            helperText: 'Builder',
            authRoles: [
                UserRole.COMPANYADMIN,
                UserRole.SUPERADMIN,
                UserRole.EMPLOYEE,
            ],
        },
        {
            label: 'Experience',
            path: '/experiences/:id',
            Icon: Trello,
            Element: ExperienceDetail,
            authRoles: [
                UserRole.COMPANYADMIN,
                UserRole.SUPERADMIN,
                UserRole.EMPLOYEE,
            ],
        },
        {
            path: '/experiences/:id/builder',
            Element: Builder,
            authRoles: [
                UserRole.COMPANYADMIN,
                UserRole.SUPERADMIN,
                UserRole.EMPLOYEE,
            ],
        },
        {
            label: 'Builds',
            path: '/downloads',
            Icon: Download,
            Element: Applications,
            navigation: true,
            helperText: 'Application',
            authRoles: [UserRole.SUPERADMIN],
        },
        {
            label: 'Profile',
            path: '/userProfile/',
            Icon: User,
            navigation: true,
            Element: UserProfile,
            helperText: 'Account',
            authRoles: [
                UserRole.COMPANYADMIN,
                UserRole.SUPERADMIN,
                UserRole.EMPLOYEE,
            ],
        },
        {
            label: 'Logout',
            path: '/logout',
            Icon: LogOut,
            navigation: true,
            Element: Logout,
            authRoles: [
                UserRole.COMPANYADMIN,
                UserRole.SUPERADMIN,
                UserRole.EMPLOYEE,
            ],
        },
        { path: '/login', Element: Login },
        { path: '/register', Element: Register },
        { path: '/forgot-password', Element: ForgotPassword },
        { path: '/reset-password', Element: ResetPassword },
    ],
    signInWithToken: SignInWithTokenApi,
    authTokenLocalStorageKey: 'vendx-token',
    PageNotFound,
    PageUnAuthorized: PageNotFound,
    loginRoute: '/login',
    timeout: 30,
    signout: () => {
        return new Promise((resolve) => {
            window.location.href = '/logout';
            resolve(true);
        });
    },
    PageLoader,
};
export default config;
