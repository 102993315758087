import clsx from 'clsx';
import React from 'react';
import styles from './textarea.module.css';

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    error?: boolean;
    helperText?: string;
    className?: string;
}

const TextArea = React.forwardRef(
    (props: Props, ref: React.LegacyRef<HTMLTextAreaElement> | undefined) => {
        const { label, error, helperText, className, ...elementProps } = props;

        return (
            <div className="grid gap-2 my-3">
                {label && (
                    <label
                        className="block uppercase tracking-wide text-xs font-semibold"
                        htmlFor={elementProps.id}
                    >
                        {label}
                        {props.required && (
                            <span className="text-red-600 text-xs"> *</span>
                        )}
                    </label>
                )}
                <textarea
                    className={clsx(
                        styles.input,
                        error && styles['error-input'],
                        className && className,
                    )}
                    ref={ref}
                    {...elementProps}
                />
                {helperText && (
                    <p
                        className={`${
                            error ? 'text-red-500' : 'text-gray-600'
                        } text-xs italic`}
                    >
                        {helperText}
                    </p>
                )}
            </div>
        );
    },
);

export default TextArea;
