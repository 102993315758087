import React from 'react';
import Device from '../../Model/Device';
import Modal from '../../Components/Modal';
import Experience from '../../Model/Experience';
import { DeviceService, ExperienceService } from '../../api';
import { CheckSquare, Square } from '../../Components/Icon';
import ExperienceFeatures from '../Experiences/ExperienceFeatures';
import { formatDate } from '../../utils/formatText';

interface Props {
    onClose: () => void;
    device: Device;
    refreshData: () => void;
}

function ExperienceSelector({ onClose, device, refreshData }: Props) {
    const [exps, setExps] = React.useState<Experience[]>([]);
    const [selectedExp, setSelectedExp] = React.useState<Experience>();

    React.useEffect(() => {
        ExperienceService.GetExperiences().then((response) => {
            setExps(response);
            const temp = response.find((el) => el.id === device.experienceId);
            if (temp) {
                setSelectedExp(temp);
            }
        });
    }, []);

    const updateDevice = (id: string) => {
        DeviceService.UpdateDevice({
            ...device,
            experienceId: id,
        }).then(() => {
            onClose();
            refreshData();
        });
    };

    const handleSelection = (val: Experience) => {
        if (val.id !== device.id) {
            setSelectedExp(val);
            updateDevice(val.id);
        }
    };
    return (
        <Modal
            open
            close={onClose}
            className="min-h-0"
            style={{ maxHeight: '60vh' }}
        >
            <h4 className="text-xl font-semibold">DEVICE EXPERIENCE</h4>
            <p className="mt-4">
                Select an experience you would like to add to the device
            </p>
            {device.isLocked && (
                <p className="text-gray-600 textxs italic text-xs">
                    *This is a locked device. Experience updates are not
                    propagated to locked devices.
                </p>
            )}
            <div className="flex flex-col gap-3 mt-4 mb-16">
                {exps.map((exp) => (
                    <div
                        key={exp.id}
                        className="flex gap-2 bg-gray-50 shadow-md rouned-md p-4 items-start hover:bg-gray-100"
                        role="button"
                        onClick={() => handleSelection(exp)}
                        onKeyDown={(e) =>
                            e.key === 'Enter' && handleSelection(exp)
                        }
                        tabIndex={0}
                    >
                        {selectedExp?.id === exp.id ? (
                            <CheckSquare className="text-green-600 w-6 h-6" />
                        ) : (
                            <Square className="w-6 h-6" />
                        )}
                        <div>
                            <p className="font-semibold">{exp.name}</p>
                            <p>{exp.dateUpdated}</p>
                            <p className="text-xs text-gray-600">
                                <strong>Date Updated: </strong>
                                {formatDate(exp.dateUpdated)}
                            </p>
                            <ExperienceFeatures exp={exp} />
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    );
}

export default ExperienceSelector;
