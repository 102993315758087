import React from 'react';
import { useParams } from 'react-router-dom';
import PortalWrapper from '../../Components/Layout/PortalWrapper';
import { CompanyService } from '../../api';
import Company from '../../Model/Company';
import { formatLocation } from '../../utils/formatText';
import Thumbnail from '../../Components/Thumbnail';
import Button from '../../Components/Button';
import { Edit3 } from '../../Components/Icon';
import CompanyModal from './CompanyModal';
import Stores from './Stores';
import useAuth from '../../Components/LayoutProvider/AuthProvider/useAuth';
import { AuthUser } from '../../Model/AuthUser';

function CompanyDetail() {
    const { id } = useParams<{ id: string }>();
    const [company, setCompany] = React.useState<Company>();
    const [modal, setModal] = React.useState(false);
    const { user } = useAuth<AuthUser>();

    const getData = () => {
        CompanyService.GetCompanyById(
            user.companyId ? user.companyId : (id as string),
        ).then((response) => {
            setCompany(response);
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    if (!company) return null;

    return (
        <PortalWrapper>
            <div className="flex flex-wrap gap-4 items-start bg-white rounded-md p-4">
                <div>
                    <Thumbnail
                        name={company.name}
                        url={company.logoUrl}
                        size="large"
                    />
                </div>
                <div>
                    <p className="text-xs font-bold mt-2">NAME</p>
                    <h1 className="text-xl lg:text-2xl font-thin">
                        {company?.name}
                    </h1>
                    <p className="text-xs font-bold mt-2">LOCATION</p>
                    <h1 className="text-xl lg:text-2xl font-thin">
                        {formatLocation(
                            company.addressLine1,
                            company.addressLine2,
                            company.city,
                            company.state,
                            'US',
                            company.zipCode,
                        )}
                    </h1>
                    <Button
                        onClick={() => setModal(true)}
                        className="bg-secondary flex gap-2 text-white justify-center items-center mt-4 py-1.5"
                    >
                        <Edit3 className="w-6 h-6" /> EDIT DETAILS
                    </Button>
                </div>
            </div>
            <h1 className="text-xl font-bold my-4">STORES/VENUE</h1>
            <Stores companyId={company.id} />
            {modal && (
                <CompanyModal
                    onClose={() => {
                        setModal(false);
                    }}
                    company={company}
                    refreshData={getData}
                />
            )}
        </PortalWrapper>
    );
}

export default CompanyDetail;
