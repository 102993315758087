import React from 'react';
import LayoutProvider from './Components/LayoutProvider';
import config from './config';
import { NotificationProvider } from './Components/Notification';
import { ConfirmationProvider } from './Components/ConfirmationDialog';

function App() {
    return (
        <ConfirmationProvider>
            <NotificationProvider>
                <LayoutProvider config={config} />
            </NotificationProvider>
        </ConfirmationProvider>
    );
}
export default App;
