/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */

import PageData, { PageType } from '../../../Model/PageData';
import VendExp from '../../../Model/VendExp';
import VendexElement, { VendexElementType } from '../../../Model/VendexElement';
import View from '../../../Model/View';
import {
    deleteStyleProperties,
    setStyleProperties,
} from '../../../utils/builderUtils';
import { BuilderReducerState } from './reducer';

export const setView = (
    state: BuilderReducerState,
    payload: View,
): BuilderReducerState => {
    let newPage;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (payload !== View.Preview) {
        newPage = state.previewPage ? state.previewPage : state.pages[0];
    }
    return { ...state, view: payload, previewPage: newPage };
};

export const setPreviewPage = (
    state: BuilderReducerState,
    payload: string,
): BuilderReducerState => {
    const newPage = state.pages.find((element) => element.id === payload);
    return { ...state, previewPage: newPage };
};

export const updatePage = (state: BuilderReducerState): BuilderReducerState => {
    if (!state.previewPage) {
        return state;
    }
    const temp = [...state.pages];
    const existingPageIndex = state.pages.findIndex(
        (element) => element.id === state?.previewPage?.id,
    );
    temp[existingPageIndex] = state?.previewPage;
    return { ...state, pages: temp };
};

export const setPageName = (
    state: BuilderReducerState,
    payload: string,
): BuilderReducerState => {
    if (!state.previewPage) {
        return state;
    }
    return { ...state, previewPage: { ...state.previewPage, name: payload } };
};

export const addPage = (
    state: BuilderReducerState,
    payload?: PageType,
): BuilderReducerState => {
    const numericDate = Date.now();
    const time = new Date(numericDate).getTime() % (24 * 60 * 60 * 1000);
    const newPage: PageData = {
        id: `page_${time}`,
        elements: [],
        name: `NEW PAGE`,
    };
    if (payload) {
        newPage.id = payload;
        newPage.name = payload;
    }
    // TODO ADD STYLES
    return {
        ...state,
        previewPage: newPage,
        pages: [...state.pages, newPage],
    };
};

export const addElement = (
    state: BuilderReducerState,
    payload: VendexElementType,
): BuilderReducerState => {
    if (!state.previewPage) {
        return state;
    }
    const numericDate = Date.now();
    const time = new Date(numericDate).getTime() % (24 * 60 * 60 * 1000);
    const elementId = `element_${state.previewPage.id}_${time}`;
    const newELData = getNewElement(payload, elementId);
    if (newELData) {
        return updateStyles(
            {
                ...state,
                previewPage: {
                    ...state.previewPage,
                    elements: [
                        ...state.previewPage.elements,
                        newELData.element,
                    ],
                },
            },
            { selector: `#${elementId}`, properties: newELData.styles },
        );
    }
    return state;
};

export const deleteElement = (
    state: BuilderReducerState,
    payload: string,
): BuilderReducerState => {
    if (!state.previewPage) {
        return state;
    }
    const tempElements = [...state.previewPage.elements];
    const elementIndex1 = tempElements.findIndex((el) => el.id === payload);
    tempElements.splice(elementIndex1, 1);
    const newPage = {
        ...(state.previewPage as PageData),
        elements: tempElements,
    };
    return deleteStyles(
        {
            ...state,
            previewPage: newPage,
        },
        `#${payload}`,
    );
};

export const updateElement = (
    state: BuilderReducerState,
    payload: VendexElement,
): BuilderReducerState => {
    if (!state.previewPage) {
        return state;
    }
    const tempElements = [...state.previewPage.elements];
    const elementIndex = tempElements.findIndex((el) => el.id === payload.id);
    tempElements[elementIndex] = payload;
    const newPage = {
        ...(state.previewPage as PageData),
        elements: tempElements,
    };
    return {
        ...state,
        previewPage: newPage,
    };
};

export const propagateChanges = (
    state: BuilderReducerState,
): BuilderReducerState => {
    return {
        ...state,
        experience: {
            ...(state.experience as VendExp),
            styles: state.styles,
            script: state.script,
            pages: state.pages,
        },
    };
};

export const deletePreviewPage = (
    state: BuilderReducerState,
): BuilderReducerState => {
    if (!state.previewPage || !(state.pages.length > 1)) {
        return state;
    }
    const tempPages = [...state.pages];
    let tempState = { ...state };
    state.previewPage.elements.forEach((el) => {
        tempState = deleteStyles(tempState, `#${el.id}`);
    });
    tempState = deleteStyles(tempState, `#${state.previewPage.id}`);
    const pageIndex = tempPages.findIndex(
        (el) => el.id === (state.previewPage as PageData).id,
    );
    tempPages.splice(pageIndex, 1);
    const newPreviewPage = tempPages[0];

    // TODO DELETE STYLES
    return {
        ...tempState,
        pages: tempPages,
        previewPage: newPreviewPage,
    };
};

export const updateStyles = (
    state: BuilderReducerState,
    payload: { selector: string; properties: { [key: string]: string } },
): BuilderReducerState => {
    const newStyles = setStyleProperties(payload.selector, payload.properties);
    return { ...state, styles: newStyles };
};

export const deleteStyles = (
    state: BuilderReducerState,
    payload: string,
): BuilderReducerState => {
    const newStyles = deleteStyleProperties(payload);
    return { ...state, styles: newStyles };
};

const getNewElement = (
    type: VendexElementType,
    elementId: string,
):
    | { element: VendexElement; styles: { [key: string]: string } }
    | undefined => {
    switch (type) {
        case VendexElementType.IMAGE:
            return {
                element: {
                    id: elementId,
                    type: VendexElementType.IMAGE,
                    metaData: {
                        type: 'STATIC',
                        source: '',
                    },
                },
                styles: {
                    position: 'absolute',
                },
            };
        case VendexElementType.TEXT:
            return {
                element: {
                    id: elementId,
                    type: VendexElementType.TEXT,
                    metaData: {
                        type: 'STATIC',
                        value: '',
                    },
                },
                styles: {
                    position: 'absolute',
                },
            };
        case VendexElementType.TIMER:
            return {
                element: {
                    id: elementId,
                    type: VendexElementType.TIMER,
                    metaData: {
                        type: 'STRICT',
                        duration: 10,
                    },
                },
                styles: {
                    position: 'absolute',
                },
            };
        case VendexElementType.BUTTON:
            return {
                element: {
                    id: elementId,
                    type: VendexElementType.BUTTON,
                    metaData: 'BUTTON TEXT',
                },
                styles: {
                    position: 'absolute',
                    border: 'none',
                    outline: 'none',
                    cursor: 'pointer',
                    padding: '8px',
                },
            };
        case VendexElementType.INPUT:
            return {
                element: {
                    id: elementId,
                    type: VendexElementType.INPUT,
                    metaData: { name: elementId, type: 'text' },
                },
                styles: {
                    position: 'absolute',
                    border: 'none',
                    outline: 'none',
                },
            };
        case VendexElementType.KEYBOARD:
            return {
                element: {
                    id: elementId,
                    type: VendexElementType.KEYBOARD,
                },
                styles: {
                    position: 'absolute',
                    width: '95%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    'z-index': '100',
                    padding: "32px 24px",
                },
            };
        case VendexElementType.MULTILINE:
            return {
                element: {
                    id: elementId,
                    type: VendexElementType.MULTILINE,
                    metaData: { name: elementId },
                },
                styles: {
                    position: 'absolute',
                    border: 'none',
                    outline: 'none',
                },
            };
        case VendexElementType.VIDEO:
            return {
                element: {
                    id: elementId,
                    type: VendexElementType.VIDEO,
                    metaData: {
                        type: 'STATIC',
                        source: '',
                        muted: true,
                        autoplay: true,
                        loop: true,
                    },
                },
                styles: {
                    position: 'absolute',
                    'z-index': '-1',
                },
            };
        case VendexElementType.CHECK_BOX:
            return {
                element: {
                    id: elementId,
                    type: VendexElementType.CHECK_BOX,
                    metaData: {
                        name: elementId,
                    },
                },
                styles: {
                    position: 'absolute',
                    width: '24px',
                    height: '24px',
                },
            };
        case VendexElementType.RADIO:
            return {
                element: {
                    id: elementId,
                    type: VendexElementType.RADIO,
                    metaData: {
                        name: elementId,
                        value: '',
                    },
                },
                styles: {
                    position: 'absolute',
                    width: '24px',
                    height: '24px',
                },
            };
        case VendexElementType.POP_IT_GAME:
            return {
                element: {
                    id: elementId,
                    type: VendexElementType.POP_IT_GAME,
                    metaData: {
                        name: elementId,
                        popItems: [],
                        itemsInScreen: 15,
                    },
                },
                styles: {
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                },
            };
        case VendexElementType.QRCODE:
            return {
                element: {
                    id: elementId,
                    type: VendexElementType.QRCODE,
                    metaData: {
                        content: '',
                        type: 'STATIC',
                    },
                },
                styles: {
                    position: 'absolute',
                    width: '250px',
                    height: '250px',
                },
            };

        default:
            return undefined;
    }
};
