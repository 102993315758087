function isCurrentTime24HoursBefore(date: string) {
    const now = new Date();
    const oneDayInMs = 86400000; // Number of milliseconds in a day

    // Calculate the difference in milliseconds between the current time and the provided date
    const timeDiff = new Date(date).getTime() - now.getTime();

    // Check if the time difference is greater than or equal to 24 hours (in milliseconds)
    return timeDiff >= oneDayInMs;
}
export default isCurrentTime24HoursBefore;
