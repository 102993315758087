import React from 'react';
import Modal from '../../Components/Modal';
import DeviceTimeline from '../../Model/DeviceTimeline';
import CompanySelector from '../../Components/CompanySelector';
import Input from '../../Components/Input';
import { DeviceService } from '../../api';
import Button from '../../Components/Button';
import {
    emailIsValid,
    isEndDateGreaterThanStartDate,
} from '../../utils/validations';
import { formatDateForDateTimeInput } from '../../utils/formatText';
import isCurrentTime24HoursBefore from '../../utils/isCurrentTime24HoursBefore';

interface Props {
    onClose: () => void;
    deviceId: string;
    timeline?: DeviceTimeline;
    refreshData: () => void;
}

function DeviceLease({ onClose, deviceId, timeline, refreshData }: Props) {
    const [leaseTerm, setLeaseTerm] = React.useState<DeviceTimeline>(
        timeline
            ? {
                  ...timeline,
                  from: formatDateForDateTimeInput(timeline.from),
                  to: formatDateForDateTimeInput(timeline.to),
              }
            : {
                  companyId: '',
                  from: '',
                  to: '',
                  deviceId,
              },
    );
    const [showError, setShowError] = React.useState(false);
    const [apiError, setApiError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [isUpdating, setIsUpdating] = React.useState(false);

    React.useEffect(() => {
        if (timeline && timeline.id) {
            setIsUpdating(true);
        }
    }, [timeline]);

    const isValid = (): boolean => {
        setApiError(null);
        return leaseTerm.companyId.length > 0 &&
            isEndDateGreaterThanStartDate(leaseTerm.from, leaseTerm.to) &&
            leaseTerm.deviceId.length > 0 &&
            !leaseTerm.representativeEmail
            ? true
            : emailIsValid(leaseTerm.representativeEmail);
    };

    const handleSubmit = () => {
        if (isValid()) {
            setLoading(true);
            const req = timeline?.id
                ? DeviceService.UpdateLease(leaseTerm)
                : DeviceService.LeaseDevice(leaseTerm);
            req.then(() => {
                refreshData();
                onClose();
            })
                .catch((e) => {
                    setApiError(e.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setShowError(true);
        }
    };

    const handleChange = (name: string, value: string) => {
        setLeaseTerm({ ...leaseTerm, [name]: value });
    };

    return (
        <Modal open close={onClose}>
            <p className="text-xl font-semibold primary">LEASE DEVICE</p>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit();
                }}
            >
                <CompanySelector
                    value={leaseTerm.companyId}
                    onChange={(val) => handleChange('companyId', val)}
                    allowUpdate={!isUpdating}
                />
                <Input
                    label="REPRESENTATIVE NAME"
                    type="text"
                    name="representativeName"
                    onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                    }
                    value={leaseTerm.representativeName}
                />
                <Input
                    label="REPRESENTATIVE EMAIL"
                    type="email"
                    name="representativeEmail"
                    onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                    }
                    value={leaseTerm.representativeEmail}
                />
                <Input
                    label="FROM"
                    type="datetime-local"
                    name="from"
                    disabled={
                        !isCurrentTime24HoursBefore(leaseTerm.from) &&
                        isUpdating
                    }
                    error={
                        showError &&
                        isEndDateGreaterThanStartDate(
                            leaseTerm.from,
                            leaseTerm.to,
                        )
                    }
                    onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                    }
                    value={leaseTerm.from}
                />
                <Input
                    label="TO"
                    type="datetime-local"
                    name="to"
                    disabled={
                        !isCurrentTime24HoursBefore(leaseTerm.from) &&
                        isUpdating
                    }
                    error={
                        showError &&
                        isEndDateGreaterThanStartDate(
                            leaseTerm.from,
                            leaseTerm.to,
                        )
                    }
                    onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                    }
                    value={leaseTerm.to}
                />
                <p className="text-xs text-gray-600 italic">
                    *Updates to date and time can be made upto 24 hours prior to
                    the start date.
                </p>
                {apiError && (
                    <p className="text-xs text-red-600 mt-2 italic">
                        {apiError}
                    </p>
                )}
                <div className="flex gap-2 justify-end mt-2">
                    <Button
                        className="bg-primary-light text-white"
                        type="submit"
                        loading={loading}
                    >
                        Submit
                    </Button>
                </div>
            </form>
        </Modal>
    );
}

export default DeviceLease;
