import React from 'react';
import Viewer from '@vendx/engine';
import { BuilderContext } from '../state';
import DeviceBoundry from './DeviceBoundry';
import '@vendx/engine/dist/main.css';
import VendExp from '../../../Model/VendExp';

function Emulator() {
    const { pages, script, previewPage, styles } =
        React.useContext(BuilderContext).state;
    const [refresh, setRefresh] = React.useState(false);

    React.useEffect(() => {
        // watch changes on builder to refresh
        if (!refresh) {
            setRefresh(true);
            setTimeout(() => {
                setRefresh(false);
            }, 500);
        }
    }, [previewPage, script]);

    const experience = React.useMemo(() => {
        return {
            pages,
            script,
            styles,
        } as VendExp;
    }, [pages, script, styles]);

    if (refresh) return null;

    return (
        <DeviceBoundry>
            <Viewer
                experience={experience}
                previewPageId={previewPage ? previewPage.id : undefined}
            />
        </DeviceBoundry>
    );
}

export default Emulator;
