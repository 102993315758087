import axios from 'axios';
import instance from './instance';
import { AuthUser, UserRole } from '../Model/AuthUser';
import SignUpReq from '../Model/SignUpReq';
import InvitedUser from '../Model/InvitedUser';
import { User } from '../Model/User';

function SignInWithTokenApi(): Promise<AuthUser> {
    return instance.get(`/auth/token-login`).then((res) => {
        return res.data;
    });
}

function SignInWithEmailPasswordApi(
    email: string,
    password: string,
): Promise<AuthUser> {
    return instance
        .post(`/auth/login`, { email: email.toLocaleLowerCase(), password })
        .then((res) => {
            localStorage.setItem('vendx-token', res.data.accessToken);
            return res.data;
        });
}

function SignupApi(user: SignUpReq): Promise<AuthUser> {
    return instance.post(`/auth/register-employee`, user).then((res) => {
        return res.data;
    });
}

function SendForgotPasswordLink(email: string): Promise<boolean> {
    return instance
        .post(
            `/auth/forgot-password?email=${encodeURI(
                email.toLocaleLowerCase(),
            )}`,
        )
        .then(() => {
            return true;
        });
}

function ResetForgottenPassword(req: {
    password: string;
    token: string;
}): Promise<boolean> {
    return axios
        .put(
            `${process.env.REACT_APP_SERVICE_BASE_URL}/auth/reset-forgotten-password`,
            {
                token: req.token,
                password: req.password,
            },
        )
        .then(() => {
            return true;
        });
}

function ResetPassword(
    currentPassword: string,
    newPassword: string,
    id: string,
): Promise<boolean> {
    return instance
        .put(`/auth/reset-password`, {
            id,
            NewPassword: newPassword,
            CurrentPassword: currentPassword,
        })
        .then((response) => {
            return response.data;
        });
}

function InviteUsersApi(
    invites: { email: string; companyId: string; role: UserRole }[],
): Promise<boolean> {
    return instance.post(`/invited-users`, invites).then(() => {
        return true;
    });
}

function DeleteInvitation(id: string): Promise<boolean> {
    return instance.delete(`/invited-users/${id}`).then(() => {
        return true;
    });
}

function GetInvitedUsers(): Promise<InvitedUser[]> {
    return instance.get(`/invited-users`).then((response) => {
        return response.data;
    });
}

function GetRegisteredUsers(): Promise<User[]> {
    return instance.get(`/users`).then((response) => {
        return response.data;
    });
}

function DisableUser(id: string): Promise<boolean> {
    return instance.put(`/user/disable?Id=${id}`).then(() => {
        return true;
    });
}

function EnableUser(id: string): Promise<boolean> {
    return instance.put(`/user/enable?Id=${id}`).then(() => {
        return true;
    });
}

function UpdateUser(user: AuthUser): Promise<boolean> {
    return instance.put(`/user`, user).then(() => {
        return true;
    });
}

function GetUser(id: string): Promise<AuthUser> {
    return instance.get(`/user?id=${id}`).then((response) => {
        return response.data;
    });
}

export {
    SignInWithTokenApi,
    SignInWithEmailPasswordApi,
    SignupApi,
    SendForgotPasswordLink,
    ResetForgottenPassword,
    InviteUsersApi,
    DeleteInvitation,
    GetInvitedUsers,
    GetRegisteredUsers,
    DisableUser,
    EnableUser,
    UpdateUser,
    GetUser,
    ResetPassword,
};
