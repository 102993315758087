import React from 'react';
import VendexElement, {
    VendexKeyboardMeta,
} from '../../../Model/VendexElement';
import { BuilderActionType, BuilderContext } from '../state';
import Select from '../../../Components/Select';
import TextArea from '../../../Components/TextArea';
import BackgroundSelector from '../BackgroundSelector';
import DimensionSelector from '../DimensionSelector';
import TypographySelector from '../TypographySelector';
import PositionSelector from '../PositionSelector';
import ActionSelector from '../ActionSelector';
import VendexAction from '../../../Model/VendexAction';

type Props = {
    element: VendexElement;
};
const DefaultKeyboardConfig = {
    alwaysVisible: false,
    numberLayout: [
        '1 2 3 4 5 6 7 8 9 0 {bksp}',
        `- / : ( ) $ & @ " {enter}`,
        "{character} . , ? ! ' {smileys}",
        '{lowercase} {space} {uppercase}',
    ],
    characterLayout: [
        '[ ] } { } # % ^ * + {bksp}',
        `_ \\ | ~ < > = $ & @ " {enter}`,
        "{number} . , ? ! ' {smileys}",
        '{lowercase} {space} {uppercase}',
    ],
    lowercaseLayout: [
        'q w e r t y u i o p {bksp}',
        'a s d f g h j k l {enter}',
        'z x c v b n m .com',
        '{uppercase} . {space} @ {number}',
    ],
    uppercaseLayout: [
        'Q W E R T Y U I O P {bksp}',
        'A S D F G H J K L {enter}',
        'Z X C V B N M , . {smileys}',
        '{lowercase} . {space} @ {number}',
    ],
    smileyLayout: [
        '😀 😊 😅 😂 🙂 😉 😍 😛 😠 😎 {bksp}',
        `😏 😬 😭 😓 😱 😪 😬 😴 😯 {enter}`,
        '😐 😇 🤣 😘 😚 😆 😡 😥 😓 🙄 {number}',
        '{lowercase} {space} {uppercase}',
    ],
};
function KeyboardDetail({ element }: Props) {
    const data = (element.metaData ?? {
        alwaysVisible: false,
    }) as VendexKeyboardMeta;
    const { dispatch } = React.useContext(BuilderContext);

    const handleChange = (value: VendexElement) => {
        dispatch({
            type: BuilderActionType.UPDATE_ELEMENT,
            payload: value,
        });
    };

    const handleDataChange = (name: string, value: string | boolean) => {
        handleChange({
            ...element,
            metaData: { ...data, [name]: value },
        });
    };
    const handleActionChange = (actions: VendexAction[]) => {
        handleChange({
            ...element,
            actions,
        });
    };

    return (
        <div className="p-4">
            <div className="flex gap-3 mb-3">
                <p className="w-40 font-semibold">Always Visible</p>
                <Select
                    value={data.alwaysVisible ? 'Yes' : 'No'}
                    onChange={(e) =>
                        handleDataChange(
                            'alwaysVisible',
                            e.target.value === 'Yes',
                        )
                    }
                >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </Select>
            </div>
            <BackgroundSelector
                selector={`#${element.id}`}
                hasImageSelection={false}
            />
            <PositionSelector selector={`#${element.id}`} />
            <DimensionSelector selector={`#${element.id}`} />
            <TypographySelector
                selector={`#${element.id}`}
                hasAlignment={false}
            />
            <p className="w-40 font-semibold">Keys Layout</p>
            <TextArea
                value={
                    data.lowercaseLayout ??
                    DefaultKeyboardConfig.lowercaseLayout
                }
                onChange={(e) =>
                    handleDataChange('lowercaseLayout', e.target.value)
                }
                label="Lowercase layout"
            />
            <TextArea
                value={
                    data.uppercaseLayout ??
                    DefaultKeyboardConfig.uppercaseLayout
                }
                onChange={(e) =>
                    handleDataChange('uppercaseLayout', e.target.value)
                }
                label="Uppercase layout"
            />

            <TextArea
                value={data.numberLayout ?? DefaultKeyboardConfig.numberLayout}
                onChange={(e) =>
                    handleDataChange('numberLayout', e.target.value)
                }
                label="Number layout"
            />
            <TextArea
                value={
                    data.characterLayout ??
                    DefaultKeyboardConfig.characterLayout
                }
                onChange={(e) =>
                    handleDataChange('characterLayout', e.target.value)
                }
                label="Character layout"
            />
            <TextArea
                value={data.smileyLayout ?? DefaultKeyboardConfig.smileyLayout}
                onChange={(e) =>
                    handleDataChange('smileyLayout', e.target.value)
                }
                label="Smily layout"
            />
            <ActionSelector
                defaultTrigger="keyboardsubmit"
                actions={element.actions}
                onChange={handleActionChange}
            />
        </div>
    );
}

export default KeyboardDetail;
