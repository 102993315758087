import React from 'react';
import ButtonDetail from './ButtonDetail';
import CheckboxDetail from './CheckboxDetail';
import ImageDetail from './ImageDetail';
import InputDetail from './InputDetail';
import MultilinetDetail from './MultilineDetail';
import PopItDetail from './PopItDetail';
import QrDetail from './QrDetail';
import RadioDetail from './RadioDetail';
import TextDetail from './TextDetail';
import TimerDetail from './TimerDetail';
import VideoDetail from './VideoDetail';
import VendexElement, { VendexElementType } from '../../../Model/VendexElement';
import KeyboardDetail from './KeyboardDetail';

function ElementDetail({ element }: { element: VendexElement }) {
    switch (element.type) {
        case VendexElementType.IMAGE:
            return <ImageDetail element={element} />;
        case VendexElementType.TEXT:
            return <TextDetail element={element} />;
        case VendexElementType.TIMER:
            return <TimerDetail element={element} />;
        case VendexElementType.BUTTON:
            return <ButtonDetail element={element} />;
        case VendexElementType.INPUT:
            return <InputDetail element={element} />;
        case VendexElementType.MULTILINE:
            return <MultilinetDetail element={element} />;
        case VendexElementType.VIDEO:
            return <VideoDetail element={element} />;
        case VendexElementType.CHECK_BOX:
            return <CheckboxDetail element={element} />;
        case VendexElementType.RADIO:
            return <RadioDetail element={element} />;
        case VendexElementType.POP_IT_GAME:
            return <PopItDetail element={element} />;
        case VendexElementType.QRCODE:
            return <QrDetail element={element} />;
        case VendexElementType.KEYBOARD:
            return <KeyboardDetail element={element} />;
        default:
            return null;
    }
}

export default ElementDetail;
