import VendexAction from './VendexAction';
import VendexElement from './VendexElement';

export default interface PageData {
    id: string;
    name: string;
    elements: VendexElement[];
}
export enum PageType {
    SCREEN_IDLE = 'SCREEN_IDLE',
    SCREEN_DEFAULT = 'SCREEN_DEFAULT',
    SCREEN_VENDING = 'SCREEN_VENDING',
    SCREEN_VEND_SUCCESS = 'SCREEN_VEND_SUCCESS',
    SCREEN_VEND_ERROR = 'SCREEN_VEND_ERROR',
    SCREEN_PRODUCT_OUT_OF_STOCK = 'SCREEN_PRODUCT_OUT_OF_STOCK',
    SCREEN_DEVICE_OUT_OF_STOCK = 'SCREEN_DEVICE_OUT_OF_STOCK',
    SCREEN_DUPLICATE_QR = 'SCREEN_DUPLICATE_QR',
    SCREEN_INVALID_QR = 'SCREEN_INVALID_QR',
    SCREEN_OFFLINE = 'SCREEN_OFFLINE',
}

export type PageTimer = {
    time: number;
    type?: 'STRICT' | 'IDLE';
    actions: VendexAction[];
};
