import React from 'react';
import { Link } from 'react-router-dom';
import Device from '../../Model/Device';
import Experience from '../../Model/Experience';
import { ExperienceService } from '../../api';
import { AlertCircle, ExternalLink, Info, Trello } from '../../Components/Icon';
import ExperienceFeatures from '../Experiences/ExperienceFeatures';
import { formatDate } from '../../utils/formatText';
import Button from '../../Components/Button';
import ExperienceSelector from './ExperienceSelector';
import useAuth from '../../Components/LayoutProvider/AuthProvider/useAuth';
import { AuthUser, UserRole } from '../../Model/AuthUser';

interface Props {
    device: Device;
    refreshData: () => void;
}

function DeviceExperience({ device, refreshData }: Props) {
    const [devExp, setDevExp] = React.useState<Experience>();
    const { user } = useAuth<AuthUser>();

    const [expModal, setExpModal] = React.useState(false);

    React.useEffect(() => {
        if (device.experienceId) {
            ExperienceService.GetExperience(device.experienceId).then((exp) => {
                setDevExp(exp);
            });
        }
    }, [device]);
    return (
        <>
            <div className="bg-white rounded-md p-4 flex-grow">
                {!device.experienceId && (
                    <p className="flex items-center gap-2 mt-3 bg-blue-200 rounded p-3">
                        <Info className="text-blue-600 w-6 h-6" />
                        No Experience is assigned to this device. Click on
                        update experience to select an experience.
                    </p>
                )}
                {!device.experienceId &&
                    !device.storeId &&
                    user.role !== UserRole.SUPERADMIN && (
                        <p className="flex items-center gap-2 mt-3 bg-red-200 rounded p-3">
                            <AlertCircle className="text-red-600 w-6 h-6" />
                            Assign this device to a store to add an experience.
                        </p>
                    )}
                {devExp && (
                    <div>
                        <p className="text-lg lg:text-xl font-medium">
                            EXPERIENCE
                        </p>
                        <div>
                            <p className="font-semibold">{devExp.name}</p>
                            <p>{devExp.dateUpdated}</p>
                            <p className="text-xs text-gray-600">
                                <strong>Date Updated: </strong>
                                {formatDate(devExp.dateUpdated)}
                            </p>
                            <ExperienceFeatures exp={devExp} />
                        </div>
                    </div>
                )}
                <div className="flex gap-3 mt-5">
                    {(user.role === UserRole.SUPERADMIN || device.storeId) && (
                        <Button
                            onClick={() => setExpModal(true)}
                            className="bg-secondary flex gap-2 text-white justify-center items-center"
                        >
                            <Trello className="w-6 h-6" /> UPDATE EXPERIENCE
                        </Button>
                    )}
                    {devExp && (
                        <Link to={`/experiences/${device.experienceId}`}>
                            <Button className="bg-secondary flex gap-2 text-white justify-center items-center">
                                <ExternalLink className="w-6 h-6" /> VIEW
                                EXPERIENCE
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
            {expModal && (
                <ExperienceSelector
                    device={device as Device}
                    onClose={() => setExpModal(false)}
                    refreshData={refreshData}
                />
            )}
        </>
    );
}

export default DeviceExperience;
