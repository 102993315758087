import React from 'react';
import PageSelector from '../PageSelector';
import { BuilderContext } from '../state';
import Device, { DeviceType, devices } from '../../../Model/VendexDevice';
import useWindowDimensions from '../../../utils/useWindowDimensions';
import View from '../../../Model/View';

const getInfo = (type: DeviceType): Device => {
    const selected = devices.find((el) => el.type === type);
    if (selected) {
        return selected;
    }
    return devices[0];
};

function DeviceBoundry({ children }: { children: React.ReactNode }) {
    const { device, view } = React.useContext(BuilderContext).state;
    const scaleRef = React.useRef<HTMLDivElement>(null);
    const { width } = useWindowDimensions();

    React.useEffect(() => {
        if (scaleRef.current) {
            const dev = getInfo(device);
            const { height } = scaleRef.current.getBoundingClientRect();
            const newWidth = (dev.width / dev.height) * (height - 24);
            const viewerEl = document.getElementById('VENDEX_VIEWER');
            if (viewerEl) {
                viewerEl.style.width = `${dev.width}px`;
                viewerEl.style.height = `${dev.height}px`;
                viewerEl.style.transform = `scale(${newWidth / dev.width})`;
                viewerEl.style.transformOrigin = 'top left';
                viewerEl.style.top = '12px';
                viewerEl.style.left = '0';
                viewerEl.style.position = 'absolute';
                viewerEl.style.overflow = 'hidden';
                if (view === View.CSS) {
                    viewerEl.style.left = 'unset';
                    viewerEl.style.right = '0';
                }
            }
        }
    }, [device, width, view]);

    return (
        <div ref={scaleRef} className="relative flex-grow py-3 [&>*]:shadow-lg">
            <PageSelector />
            {children}
        </div>
    );
}

export default DeviceBoundry;
