import React from 'react';
import { ExpReq } from '../../Model/Experience';
import Modal from '../../Components/Modal';
import Input from '../../Components/Input';
import TextArea from '../../Components/TextArea';
import Button from '../../Components/Button';
import {
    CreateExperience,
    DeleteExperience,
    UpdateExperience,
} from '../../api/experienceService';
import CompanySelector from '../../Components/CompanySelector';
import Checkbox from '../../Components/Checkbox';

interface Props {
    exp?: ExpReq;
    onClose: () => void;
    refreshData: () => void;
}
const initExp: ExpReq = {
    companyId: '',
    name: '',
    requiresInternet: false,
    requiresRefrigeration: false,
};
function ExperienceModal({ exp = initExp, onClose, refreshData }: Props) {
    const [data, setData] = React.useState(exp);
    const [showError, setShowError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const isValid = () => {
        return data.name.length > 0 && data.companyId.length > 0;
    };

    const handleSubmit = () => {
        if (isValid()) {
            setShowError(false);
            const req = data.hasOwnProperty('id')
                ? UpdateExperience(data)
                : CreateExperience(data);
            req.then(() => {
                refreshData();
                onClose();
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setShowError(true);
        }
    };

    const handleChange = (name: string, value: string | number | boolean) => {
        setData({ ...data, [name]: value });
    };

    const handleDelete = () => {
        setLoading(true);
        DeleteExperience(data.id as string)
            .then(() => {
                refreshData();
                onClose();
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <Modal open close={onClose}>
            <p className="text-xl font-semibold primary">
                {data.hasOwnProperty('id')
                    ? 'EDIT EXPERIENCE'
                    : 'CREATE EXPERIENCE'}
            </p>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit();
                }}
            >
                <CompanySelector
                    value={data.companyId}
                    onChange={(value) => handleChange('companyId', value)}
                    allowUpdate={!data.hasOwnProperty('id')}
                />
                <Input
                    label="Name"
                    onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                    }
                    value={data.name}
                    name="name"
                    error={showError && data.name.length === 0}
                    required
                />
                <TextArea
                    label="description"
                    onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                    }
                    value={data.description}
                    name="description"
                />
                <div className="flex flex-col md:flex-row gap-4">
                    <Checkbox
                        checked={data.requiresInternet}
                        onChange={() =>
                            handleChange(
                                'requiresInternet',
                                !data.requiresInternet,
                            )
                        }
                        id="internet require"
                        label="Requires Internet"
                    />
                    <Checkbox
                        checked={data.requiresRefrigeration}
                        onChange={() =>
                            handleChange(
                                'requiresRefrigeration',
                                !data.requiresRefrigeration,
                            )
                        }
                        id="Refrigeration require"
                        label="Requires Refrigeration"
                    />
                </div>
                <div className="flex gap-2 justify-end mt-2">
                    {data.hasOwnProperty('id') && (
                        <Button
                            className="bg-red-600 text-white"
                            loading={loading}
                            onClick={handleDelete}
                        >
                            Delete
                        </Button>
                    )}
                    <Button
                        className="bg-primary-light text-white"
                        type="submit"
                        loading={loading}
                    >
                        Submit
                    </Button>
                </div>
            </form>
        </Modal>
    );
}

export default ExperienceModal;
