import React from 'react';
import Button from '../../Components/Button';
import Input from '../../Components/Input';
import Modal from '../../Components/Modal';
import { BuilderActionType, BuilderContext } from './state';
import {
    extractFontProperties,
    setFontProperties,
} from '../../utils/builderUtils';
import Select from '../../Components/Select';

function FontSettings() {
    const { fontOpen } = React.useContext(BuilderContext).state;
    const [fonts, setFonts] = React.useState<{ [key: string]: string }[]>([]);
    const [showError, setShowError] = React.useState(false);
    const { dispatch } = React.useContext(BuilderContext);

    React.useEffect(() => {
        if (fontOpen) {
            const temp = extractFontProperties();
            setFonts(temp);
        }
    }, [fontOpen]);

    const handleClose = () => {
        dispatch({
            type: BuilderActionType.TOGGLE_FONT,
        });
    };

    const getFileName = (src: string) => {
        if (src?.includes('url')) {
            const fileName = src.match(/\/([^/]+)\.([^./]+)"\)/)?.[1];
            return fileName ?? '';
        }
        return '';
    };

    const handleChange = (name: string, value: string, index: number) => {
        const temp = [...fonts];
        temp[index] = { ...temp[index], [name]: value };
        setShowError(false);
        setFonts(temp);
    };

    const handleSourceChange = (url: string, index: number) => {
        const regex = /(?:\.([^.]+))?$/;
        const ext = regex.exec(url)?.[1] ?? '';
        let sourceString = `url("${url}")`;
        if (ext === 'ttf') {
            sourceString += ' format("truetype")';
        }
        if (ext === 'otf') {
            sourceString += ' format("opentype")';
        }
        handleChange('src', sourceString, index);
    };

    const handleFileClick = (src: string, index: number) => {
        dispatch({
            type: BuilderActionType.SET_MEDIA_LIBRARY,
            payload: {
                open: 'MEDIA',
                onChange: (url: string) => handleSourceChange(url, index),
                selected: getFileName(src),
                type: 'FONT',
            },
        });
    };

    const handleRemove = (index: number) => {
        const temp = [...fonts];
        temp.splice(index, 1);
        setFonts(temp);
    };

    const handleAdd = () => {
        const temp = [...fonts];
        temp.push({});
        setFonts(temp);
    };

    const handleApply = () => {
        let isValid = true;
        fonts.forEach((el) => {
            if (
                !el['font-family'] ||
                el['font-family'].length === 0 ||
                !el.src ||
                el.src.length === 0
            ) {
                isValid = false;
            }
        });
        if (!isValid) {
            setShowError(true);
        } else {
            const newStyles = setFontProperties(fonts);
            dispatch({
                type: BuilderActionType.SET_CSS,
                payload: newStyles,
            });
            handleClose();
        }
    };

    return (
        <Modal
            open={fontOpen}
            close={handleClose}
            className="max-w-sm z-50 overflow-y-auto min-h-0"
            style={{ maxHeight: '80vh' }}
        >
            <div className="p-4">
                <h4 className="text-xl font-semibold">FONT SETTINGS</h4>
                <br />
                {fonts.length === 0 && <p>Please add fonts to get started</p>}
                {fonts.map((el, index) => (
                    <div className="relative pb-9" key={`${index.toString()}`}>
                        <div className="mt-2">
                            <p className="font-semibold">Font Family *</p>
                            <p className="text-xs italic text-gray-400 font-thin">
                                Please avoid white spaced font family name
                            </p>
                            <Input
                                value={el['font-family']}
                                onChange={(e) =>
                                    handleChange(
                                        'font-family',
                                        e.target.value,
                                        index,
                                    )
                                }
                            />
                        </div>
                        <div className="mt-2">
                            <p className="font-semibold">Font File *</p>
                            <div
                                className="p-4 cursor-pointer overflow-hidden truncate text-xs border border-dotted border-gray-600 hover:opacity-70 hover:text-blue-600 rounded-md text-center"
                                onClick={() => handleFileClick(el.src, index)}
                                onKeyDown={(e) =>
                                    e.key === 'Enter' &&
                                    handleFileClick(el.src, index)
                                }
                                tabIndex={0}
                                role="button"
                                style={{ minHeight: '24px' }}
                            >
                                {el.src ?? 'CLICK TO ADD FILE'}
                            </div>
                        </div>
                        <div className="mt-2">
                            <p className="font-semibold">Font Weight</p>
                            <Select
                                value={el['font-weight']}
                                onChange={(e) =>
                                    handleChange(
                                        'font-weight',
                                        e.target.value,
                                        index,
                                    )
                                }
                            >
                                <option value="">SELECT WEIGHT</option>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={300}>300</option>
                                <option value={400}>400</option>
                                <option value={500}>500</option>
                                <option value={600}>600</option>
                                <option value={700}>700</option>
                                <option value={800}>800</option>
                                <option value={900}>900</option>
                            </Select>
                        </div>
                        <div className="mt-2">
                            <p className="font-semibold">Font Style</p>
                            <Select
                                value={el['font-style']}
                                onChange={(e) =>
                                    handleChange(
                                        'font-style',
                                        e.target.value,
                                        index,
                                    )
                                }
                            >
                                <option value="">SELECT STYLE</option>
                                <option value="italic">italic</option>
                                <option value="normal">normal</option>
                            </Select>
                        </div>
                        <Button
                            className="absolute right-0 bottom-0 bg-red-600 text-white py-1 text-xs"
                            onClick={() => handleRemove(index)}
                        >
                            Remove
                        </Button>
                    </div>
                ))}
                {showError && (
                    <p style={{ color: 'red' }}>
                        Please fill out the required fields
                    </p>
                )}
                <div className="flex gap-3 mt-4 items-start">
                    <Button
                        onClick={handleAdd}
                        className="bg-primary text-white w-full"
                    >
                        Add Font
                    </Button>
                    <Button
                        onClick={handleApply}
                        className="bg-primary text-white w-full"
                    >
                        Update
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default FontSettings;
