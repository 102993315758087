import React from 'react';
import Navigation from './Navigation';
import Toolbar from './Toolbar';

type Props = {
    children: React.ReactNode;
};

function PortalWrapper({ children }: Props) {
    return (
        <main className="w-full h-full min-h-full flex relative before:absolute before:top-0 before:z-10 before:left-0 before:w-full before:h-16 before:lg:h-20 before:shadow-lg before:bg-primary-light">
            <Navigation />
            <div
                className="w-full h-full bg-slate-100 overflow-auto overscroll-y-none"
                style={{ WebkitOverflowScrolling: 'touch' }}
            >
                <Toolbar />
                <div className="w-full h-full max-w-9xl mx-auto px-5 md:px-6 lg:px-8 xl:px-10">
                    <article className="flex-grow flex flex-col py-6 lg:py-10 xl:py-12">
                        {children}
                    </article>
                </div>
            </div>
        </main>
    );
}

export default PortalWrapper;
