/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import useAuth from '../AuthProvider/useAuth';
import getNavigationItems from '../getNavigationItems';
import NavRoute from '../NavRoute';
import SelectedRoute from '../SelectedRoute';
import TemplateRoute from '../TemplateRoute';
import getMatchingRoute from './getMatchingRoute';

type RouteProviderProps = {
    routes: TemplateRoute[];
    loginRoute: string;
    PageNotFound: React.FC;
    PageUnAuthorized: React.FC;
    Loader: React.FC;
};

export const TemplateRouteContext = React.createContext<{
    selected: SelectedRoute | null;
    navItems: NavRoute[];
}>({ selected: null, navItems: [] });

function RouteProvider({
    routes,
    loginRoute,
    PageNotFound,
    PageUnAuthorized,
    Loader,
}: RouteProviderProps) {
    const [isInitialized, setIsInitialized] = React.useState(false);

    const [selectedRoute, setSelectedRoute] =
        React.useState<SelectedRoute | null>(null);
    const [navItems, setNavItems] = React.useState<NavRoute[]>([]);

    const auth = useAuth<{ role: string }>().user;
    const location = useLocation();

    React.useEffect(() => {
        const temp = getMatchingRoute(location.pathname, routes);
        setSelectedRoute(temp);
        setIsInitialized(true);
    }, [location]);

    React.useEffect(() => {
        const temp = getNavigationItems(routes, auth.role);
        setNavItems(temp);
    }, [auth]);

    if (!isInitialized) {
        return <Loader />;
    }

    if (selectedRoute?.authRoles && auth.role === 'visitor') {
        return (
            <Navigate to={loginRoute} state={{ from: selectedRoute.path }} />
        );
    }
    if (
        selectedRoute?.authRoles &&
        !selectedRoute?.authRoles.includes(auth.role)
    ) {
        return <Navigate to="/401" />;
    }

    return (
        <TemplateRouteContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{ selected: selectedRoute, navItems }}
        >
            <Routes>
                {routes.map((el) => (
                    <React.Fragment key={el.path}>
                        <Route path={el.path} element={<el.Element />} />
                        <Route path="/401" element={<PageUnAuthorized />} />
                        <Route element={<PageNotFound />} />
                    </React.Fragment>
                ))}
            </Routes>
        </TemplateRouteContext.Provider>
    );
}

export default RouteProvider;
