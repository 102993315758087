/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import Popup from '../Popup';
import styles from './tooltip.module.css';

interface Props {
    children: React.ReactElement;
    text: string;
    className?: string;
    disabled?: boolean;
    spacing?: number;
}

function Tooltip({ children, text, className, disabled, spacing }: Props) {
    const [isOpen, setIsOpen] = React.useState(false);
    const parentRef = React.useRef<HTMLElement | null>(null);

    const showTooltip = (parent: Element) => {
        setIsOpen(true);
        parentRef.current = parent as HTMLElement;
    };

    const hideTooltip = () => {
        setIsOpen(false);
        parentRef.current = null;
    };
    return (
        <>
            {!disabled
                ? React.cloneElement(children, {
                      ...children.props,
                      onFocus: (e: React.FocusEvent) => {
                          showTooltip(e.currentTarget);
                          children.props.onFocus?.(e);
                      },
                      onBlur: (e: React.FocusEvent) => {
                          hideTooltip();
                          children.props.onBlur?.(e);
                      },
                      onMouseOver: (e: React.MouseEvent) => {
                          showTooltip(e.currentTarget);
                          children.props.onMouseOver?.(e);
                      },
                      onMouseOut: (e: React.MouseEvent) => {
                          hideTooltip();
                          children.props.onMouseOut?.(e);
                      },
                  })
                : children}
            {!disabled && isOpen && (
                <Popup
                    spacing={spacing}
                    position="top"
                    anchorRef={parentRef}
                    onClose={hideTooltip}
                    onMouseOver={(e) => e.stopPropagation()}
                    onMouseOut={(e) => e.stopPropagation()}
                    className={clsx(styles.tooltip, className && className)}
                >
                    {text}
                </Popup>
            )}
        </>
    );
}

export default Tooltip;
