import React from 'react';
import IconButton from '../../Components/IconButton';
import { Lock, Unlock, Key} from '../../Components/Icon';
import { AuthService } from '../../api';
import { useNotification } from '../../Components/Notification';
import Tooltip from '../../Components/Tooltip';
import { User } from '../../Model/User';
import { useConfirmation } from '../../Components/ConfirmationDialog';

interface Props {
    user: User;
    refreshData: () => void;
}

function UserQuickActions({ user, refreshData }: Props) {
    const notification = useNotification();
    const confirmation = useConfirmation();

    const handleDisable = () => {
        confirmation({
            title: "Confirm", 
            message: "Are you sure you want to disable this user?",
            onConfirm: () => {
                AuthService.DisableUser(user.id).then(() => {
                    notification({
                        type: 'success',
                        duration: 6000,
                        message: `${user.firstName} ${user.lastName} was successfully disabled.`,
                    });
                    refreshData();
                });
            }
        })
    };

    const handleEnable = () => {
        confirmation({
            title: "Confirm", 
            message: "Are you sure you want to enable this user?",
            onConfirm: () => {
                AuthService.EnableUser(user.id).then(() => {
                    notification({
                        type: 'success',
                        duration: 6000,
                        message: `${user.firstName} ${user.lastName} was successfully enabled.`,
                    });
                    refreshData();
            });
            }
        })
    };

    const sendPasswordEmail = () => {
        AuthService.SendForgotPasswordLink(user.email).then(()=>{
            notification({
                type: 'success',
                duration: 6000,
                message: `Key was successfully sent!`,
            });
        }).catch((err)=>{
            notification({
                type: 'error',
                duration: 6000,
                message: `${err}`,
            });
        })
    };
    return (
        <div
            className="flex gap-3 justify-center"
            role="none"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >

            {user.isDisabled ? 
            <Tooltip text="enable user">
            <IconButton
                onClick={handleEnable}
                btnSize="small"
                className="text-green-600"
            >
                <Unlock />
            </IconButton>
            </Tooltip> 
            :
            <Tooltip text="Disable user">
                <IconButton
                    onClick={handleDisable}
                    btnSize="small"
                    className="text-red-600"
                >
                    <Lock />
                </IconButton>
            </Tooltip>
            }
            <Tooltip text="Resend Key">
                <IconButton
                    onClick={sendPasswordEmail}
                    btnSize="small"
                    className="text-blue-600"
                >
                    <Key />
                </IconButton>
            </Tooltip>
        </div>
    );
}

export default UserQuickActions;
