import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { SessionAnalytics } from '../../Model/Analytics';

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
    data: SessionAnalytics;
}

const getData = (data: SessionAnalytics) => {
    return {
        labels: ['Vend Success', 'Incomplete Session'],
        datasets: [
            {
                label: 'vend analytics',
                data: [data.success, data.total - data.success],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                ],
                borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 206, 86, 1)'],
                borderWidth: 1,
            },
        ],
    };
};

function VendChart({ data }: Props) {
    const [barData, setBarData] = React.useState(getData(data));

    React.useEffect(() => {
        setBarData(getData(data));
    }, [data]);
    return (
        <div className="bg-white/50 rounded-lg border border-gray-200 p-5 flex-grow">
            <p className="text-lg text-primary font-medium">CONVERSION</p>
            <hr className="py-1" />
            <div
                className="overflow-y-auto px-5 -mx-5"
                style={{ maxHeight: '360px' }}
            >
                <Pie data={barData} />
            </div>
        </div>
    );
}

export default VendChart;
