/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';
import { useParams } from 'react-router-dom';
import Navigation from './Navigation';
import Toolbar from './Toolbar';
import Workspace from './Workspace';
import { BuilderActionType } from './state/actions';
import { BuilderContext, BuilderReducer, initialState } from './state';
import CodeEditor from './CodeEditor';
import MediaLibrary from './MediaLibrary';
import CSSEditor from './CSSEditor';
import FontSettings from './FontSettings';
import View from '../../Model/View';
import Emulator from './Emulator';
import { ApplicationService, ExperienceService } from '../../api';
import { useConfirmation } from '../../Components/ConfirmationDialog';
import AddPage from './AddPage';

function Builder() {
    const { id } = useParams<{ id: string }>();
    const [state, dispatch] = React.useReducer(BuilderReducer, initialState);
    const [isDataLoaded, setIsDataLoaded] = React.useState(false);
    const [versionMatch, setVersionMatch] = React.useState(true);
    const {view} = state;
    const confirmation = useConfirmation();

    React.useEffect(() => {
        ExperienceService.GetVendExpById(id as string)
            .then((response) => {
                ApplicationService.GetLatestBuildVersion().then((version) =>
                    setVersionMatch(
                        version === response.builderVersion ||
                            response.builderVersion ===
                                process.env.REACT_APP_LEGACY_VERSION,
                    ),
                );
                dispatch({
                    type: BuilderActionType.SET_EXPERIENCE,
                    payload: response,
                });
            })
            .finally(() => setIsDataLoaded(true));
    }, [id]);

    React.useEffect(() => {
        if (!versionMatch) {
            confirmation({
                title: 'BUILD UPDATE',
                message:
                    'Builder has been updated to use a newer version of the build. The build that was used to create this experience is different and might have some breaking changes. If you want to make adjustments to the experience, we recommend creating a new experience using the updated builder.',
            });
        }
    }, [versionMatch]);

    return (
        <BuilderContext.Provider value={{ state, dispatch }}>
            <Navigation />
            {view === View.EDIT && <Toolbar />}
            {view === View.CSS && <CSSEditor />}
            {isDataLoaded && (
                <div className="pl-16 pt-16 min-h-screen max-h-screen flex">
                    <div className="mx-auto max-w-9xl w-full flex relative">
                        {view === View.CODE && <CodeEditor />}
                        {view === View.EDIT && <Workspace />}
                        <Emulator />
                    </div>
                </div>
            )}
            <MediaLibrary />
            <FontSettings />
            <AddPage />
        </BuilderContext.Provider>
    );
}

export default Builder;
