import React from 'react';
import Company, { CompanyReq } from '../../Model/Company';
import Modal from '../../Components/Modal';
import Input from '../../Components/Input';
import { zipCodeIsValid } from '../../utils/validations';
import Button from '../../Components/Button';
import UploadButton from '../../Components/UploadButton';
import getImageFromFile from '../../utils/getImageFromFile';
import { CompanyService } from '../../api';
import Thumbnail from '../../Components/Thumbnail';
import Select from '../../Components/Select';
import StateOptions from '../../Components/StateOptions';

interface Props {
    company?: Company | CompanyReq;
    onClose: () => void;
    refreshData: () => void;
}
const initCompany: CompanyReq = {
    name: '',
    addressLine1: '',
    city: '',
    state: '',
    zipCode: '',
};

function CompanyModal({ company = initCompany, onClose, refreshData }: Props) {
    const [data, setData] = React.useState(company);
    const [showError, setShowError] = React.useState(false);
    const [file, setFile] = React.useState<File>();
    const [loading, setLoading] = React.useState(false);

    const handleChange = (name: string, value: string) => {
        setData({ ...data, [name]: value });
    };

    const isValid = () => {
        return (
            data.name.length > 0 &&
            data.addressLine1.length > 0 &&
            data.city.length > 0 &&
            data.state.length > 0 &&
            zipCodeIsValid(data.zipCode)
        );
    };

    const handleSubmit = () => {
        if (isValid()) {
            setShowError(false);
            const req = data.hasOwnProperty('id')
                ? CompanyService.UpdateCompany(data as Company, file)
                : CompanyService.CreateCompany(data as CompanyReq, file);
            req.then(() => {
                refreshData();
                onClose();
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setShowError(true);
        }
    };

    const handleUpload = async (files: File[]) => {
        setFile(files[0]);
        const imageSource = await getImageFromFile(files[0]);
        handleChange('companyLogoUrl', imageSource);
    };

    const handleDelete = () => {
        setLoading(true);
        CompanyService.DeleteCompany((data as Company).id)
            .then(() => {
                refreshData();
                onClose();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Modal open close={onClose} className="max-w-xl">
            <div className="flex gap-4 items-center  mb-2">
                <Thumbnail name={data.name} url={data.logoUrl} />
                <p className="text-xl font-semibold primary">
                    {data.hasOwnProperty('id')
                        ? 'EDIT COMPANY'
                        : 'CREATE COMPANY'}
                </p>
            </div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit();
                }}
            >
                <Input
                    label="Name"
                    onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                    }
                    value={data.name}
                    name="name"
                    error={showError && data.name.length === 0}
                    required
                />
                <UploadButton
                    className="w-full bg-secondary text-white mb-2"
                    onSelect={handleUpload}
                >
                    {data.logoUrl && data.logoUrl.length > 0
                        ? 'CHANGE LOGO'
                        : 'UPLOAD LOGO'}
                </UploadButton>
                <Input
                    label="Street Address"
                    onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                    }
                    value={data.addressLine1}
                    name="addressLine1"
                    error={showError && data.addressLine1.length === 0}
                    required
                />
                <div className="grid md:grid-cols-2 gap-2">
                    <Input
                        label="Apt/Suite"
                        onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                        }
                        value={data.addressLine2}
                        name="addressLine2"
                    />
                    <Input
                        label="City"
                        onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                        }
                        value={data.city}
                        name="city"
                        error={showError && data.city.length === 0}
                        required
                    />
                    <Select
                        label="State"
                        onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                        }
                        value={data.state}
                        name="state"
                        error={showError && data.state.length === 0}
                        required
                    >
                        <StateOptions />
                    </Select>
                    <Input
                        label="Zip Code"
                        onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                        }
                        value={data.zipCode}
                        name="zipCode"
                        error={showError && !zipCodeIsValid(data.zipCode)}
                        required
                    />
                </div>
                <div className="flex gap-2 justify-end mt-2">
                    {data.hasOwnProperty('id') && (
                        <Button
                            className="bg-red-600 text-white"
                            loading={loading}
                            onClick={handleDelete}
                        >
                            Delete
                        </Button>
                    )}
                    <Button
                        className="bg-primary-light text-white"
                        type="submit"
                        loading={loading}
                    >
                        Submit
                    </Button>
                </div>
            </form>
        </Modal>
    );
}

export default CompanyModal;
