/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Company from '../../Model/Company';
import { GetCompanies } from '../../api/companyService';
import { AuthUser, UserRole } from '../../Model/AuthUser';
import useAuth from '../LayoutProvider/AuthProvider/useAuth';
import Select from '../Select';

interface Props {
    value: string;
    onChange: (selected: string) => void;
    allowUpdate?: boolean;
}

function CompanySelector({ value, onChange, allowUpdate = true }: Props) {
    const [data, setData] = React.useState<Company[]>([]);
    const { user } = useAuth<AuthUser>();

    React.useEffect(() => {
        if (user.companyId && user.companyId !== value) {
            onChange(user.companyId);
        }
    }, [user.companyId, value]);

    React.useEffect(() => {
        GetCompanies().then((response) => setData(response));
    }, []);

    if (user.role !== UserRole.SUPERADMIN) return null;
    return (
        <div className="grid gap-2 my-3">
            <Select
                placeholder="Select Company"
                value={value}
                id="company-selector"
                onChange={(e) => onChange(e.target.value)}
                className="bg-gray-100 text-gray-700 border rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 placeholder-gray-500 placeholder-opacity-75"
                label="Company"
                disabled={!allowUpdate}
            >
                <option className="rounded-none" value="">
                    SELECT A COMPANY
                </option>
                {data.map((comp) => (
                    <option key={comp.id} value={comp.id}>
                        {comp.name}
                    </option>
                ))}
            </Select>
        </div>
    );
}

export default CompanySelector;
