import React from 'react';

function PageNotFound() {
    return (
        <div className="w-full h-full flex flex-col justify-center items-center bg-gradient-to-r from-primary-light to-primary">
            <h3 className="font-secondary font-semibold text-4xl lg:text-5xl mt-8 uppercase text-white">
                404 NOT FOUND
            </h3>
            <h6 className="font-secondary font-semibold text-3xl lg:text-4xl text-secondary mt-8 uppercase">
                We could not find the page you are looking for
            </h6>
        </div>
    );
}

export default PageNotFound;
