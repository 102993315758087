import instance from './instance';
import DeviceLog, { DeviceLogGrid } from '../Model/DeviceLog';
import { LogsFilter } from '../Model/Analytics';
import downloadFile from '../utils/dowloadFile';
import Paginate from '../Model/Paginate';

function getLogs(filters: LogsFilter): Promise<Paginate<DeviceLogGrid>> {
    // converting dates to ISo string for server
    return instance
        .post('/logs/all', {
            ...filters,
            from: new Date(filters.from).toISOString(),
            to: filters.to ? new Date(filters.to).toISOString() : undefined,
        })
        .then((res) => res.data);
}

function DownloadAsCsv(filters: LogsFilter): Promise<boolean> {
    // converting dates to ISo string for server
    return instance
        .post(
            `/logs/csv`,
            {
                ...filters,
                from: new Date(filters.from).toISOString(),
                page: undefined,
                pageSize: undefined,
                to: filters.to ? new Date(filters.to).toISOString() : undefined,
            },
            {
                responseType: 'blob',
            },
        )
        .then((response) => {
            return downloadFile(
                response.data,
                `logs_${new Date().toLocaleString()}.csv`,
            );
        });
}

function GetLogDetail(id: string): Promise<DeviceLog> {
    return instance.get(`/logs/${id}`).then((response) => {
        return response.data;
    });
}

export { getLogs, DownloadAsCsv, GetLogDetail };
