import React from 'react';
import PageTitle from './PageTitle';
import useAuth from '../../LayoutProvider/AuthProvider/useAuth';
import { AuthUser, ROLESTRING } from '../../../Model/AuthUser';

function Toolbar() {
    const auth = useAuth<AuthUser>().user;
    return (
        <header className="sticky top-0 left-0 w-full z-20 max-w-9xl mx-auto px-5 md:px-6 lg:px-8 xl:px-10 flex h-16 lg:h-20 items-center">
            <PageTitle />
            <div className="flex-grow ml-8 flex justify-end">
                <div>
                    <p className="text-md text-white uppercase text-right font-bold">
                        {`${auth?.firstName ?? ''} ${auth?.lastName ?? ''}`}
                    </p>
                    <p className="text-white text-right text-xs uppercase">
                        {ROLESTRING[auth.role] ?? ''}
                    </p>
                </div>
            </div>
        </header>
    );
}

export default Toolbar;
