import React from 'react';
import { CellProps, useSortBy, useTable } from 'react-table';
import Product from '../../Model/Product';
import ProductQuickActions from './ProductQuickActions';
import SortingIcon from '../../Components/GridComponents/SortingIcon';
import { AuthUser, UserRole } from '../../Model/AuthUser';
import useAuth from '../../Components/LayoutProvider/AuthProvider/useAuth';
import Thumbnail from '../../Components/Thumbnail';
import { formatDate } from '../../utils/formatText';

type Props = {
    tableData: Product[];
    openEditProduct: (product: Product) => void;
    refreshData: () => void;
};

function Table({
    tableData,
    openEditProduct,
    refreshData,
}: Props): JSX.Element {
    const { user } = useAuth<AuthUser>();
    const memoizedData = React.useMemo(() => tableData, [tableData]);

    const memoizedColumns: any = React.useMemo(() => {
        const baseColumns = [
            {
                Header: 'Thumbnail',
                accessor: 'thumbnail', // accessor is the "key" in the data,
                Cell: ({ row }: CellProps<Product>) => {
                    return Thumbnail({
                        name: row.original.name,
                        url: row.original.thumbnail,
                        size: 'small',
                    });
                },
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Description',
                accessor: 'description',
                style: {
                    width: '240px',
                },
            },
            {
                Header: 'Date Created',
                accessor: 'dateCreated',
                Cell: ({ value }: CellProps<Product, string>) => {
                    return formatDate(value);
                },
            },
            {
                Header: 'Price',
                accessor: 'price',
            },
            {
                Header: ' ',
                accessor: '',
                Cell: ({ row }: CellProps<Product>) => {
                    return ProductQuickActions({
                        product: row.original,
                        refreshData,
                    });
                },
            },
        ];
        if (user.role === UserRole.SUPERADMIN) {
            baseColumns.splice(0, 0, {
                Header: 'Company',
                accessor: 'companyName',
            });
        }
        return baseColumns;
    }, [user.role]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns: memoizedColumns, data: memoizedData }, useSortBy);

    const handleRowClick = (row: any, e: any) => {
        const elementClicked = e.target.nodeName;
        if (elementClicked !== 'BUTTON') {
            openEditProduct(row.original);
        }
    };

    return (
        <table {...getTableProps()} className="w-full">
            <thead className="shadow-md">
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                            <th
                                className="bg-white/50 border-t border-b border-gray-200 p-4 hover:cursor-pointer font-bold uppercase"
                                {...column.getHeaderProps(
                                    column.getSortByToggleProps(),
                                )}
                            >
                                {column.render('Header')}
                                <SortingIcon
                                    isSorted={column.isSorted}
                                    isSortedDesc={column.isSortedDesc}
                                />
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody className="bg-white" {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr
                            {...row.getRowProps()}
                            className="hover:bg-gray-100 cursor-pointer border-b border-gray-200"
                            onClick={(e) => handleRowClick(row, e)}
                        >
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        className="text-center py-3 border-r border-r-gray-200 px-2"
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default Table;
